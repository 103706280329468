import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import { generateToastr } from '../../../core/toastMessages';
import * as actions from '../actions';
import DetergentReadingEditForm from '../components/DetergentReadingEditForm';
import Detergent, { DetergentReading } from '../models';
import {
  selectCostPerCarChartFromDate,
  selectCostPerCarChartUntilDate,
  selectDetergentPods,
  selectSiteDetergents,
  selectReadingsFromDate,
  selectReadingsUntilDate,
} from '../reducer';
import { selectModal } from '../../../store/modal';

const initialFormValues = {};

export class AddDetergentReadingDialog extends Component {
  static propTypes = {
    addDetergentReading: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    hideDialog: PropTypes.func.isRequired,
    detergents: PropTypes.arrayOf(PropTypes.instanceOf(Detergent)),
    errors: PropTypes.shape({}),
    chartFromDate: PropTypes.shape({ format: PropTypes.func }),
    chartUntilDate: PropTypes.shape({ format: PropTypes.func }),
    readingsFromDate: PropTypes.shape({ format: PropTypes.func }),
    readingsUntilDate: PropTypes.shape({ format: PropTypes.func }),
  };

  onReady = () => {
    this.props.triggerSubmit('detergentReading');
  };

  // TODO: manage validationErrors
  onSubmit = ({ readings }) => {
    let chartQuery;
    let readingsQuery;

    if (readings === undefined) {
      this.props.hideDialog();
      return;
    }
    if (this.detergentIdsAreNotNumbers(readings) || this.duplicateDetergentIds(readings)) {
      generateToastr('error', 'Invalid Field', 'Please Select a Detergent', {
        timeOut: 5000,
      });
      return;
    }
    if (this.props.chartFromDate && this.props.chartUntilDate) {
      chartQuery = {
        fromDate: this.props.chartFromDate.format('YYYY-MM-DD'),
        untilDate: this.props.chartUntilDate.format('YYYY-MM-DD'),
      };
    }
    if (this.props.readingsFromDate && this.props.readingsUntilDate) {
      readingsQuery = {
        fromDate: this.props.readingsFromDate.format('YYYY-MM-DD'),
        untilDate: this.props.readingsUntilDate.format('YYYY-MM-DD'),
        containerIds: _.map(readings, (reading) => reading.containerId),
      };
    }
    this.props.addDetergentReading(
      { readings, detergentPodId: this.props.detergentPod.id },
      chartQuery,
      readingsQuery
    );
  };

  detergentIdsAreNotNumbers = (readings) => {
    return _.find(readings, (r) => {
      return isNaN(r.containerId);
    });
  };

  duplicateDetergentIds = (readings) => {
    const detergentIds = _.map(readings, (reading) => reading.containerId);
    const duplicateFound = detergentIds.some((item, index) => {
      return detergentIds.indexOf(item) !== index;
    });
    return duplicateFound;
  };

  render() {
    const submitDisabled = checkErrors(this.props.errors, 'readings');

    return (
      <ModalDialog
        title="Add Detergent Reading"
        onReady={this.onReady}
        isOpen={this.props.isOpen}
        close={this.props.hideDialog}
        disabled={submitDisabled}
      >
        <DetergentReadingEditForm
          onSubmit={this.onSubmit}
          detergentContainers={this.props.detergentPod.detergentContainers}
          initialValues={new DetergentReading(initialFormValues)}
        />
      </ModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('detergentReading');

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  return {
    detergents: selectSiteDetergents(state),
    detergentPods: selectDetergentPods(state),
    chartFromDate: selectCostPerCarChartFromDate(state),
    chartUntilDate: selectCostPerCarChartUntilDate(state),
    readingsFromDate: selectReadingsFromDate(state),
    readingsUntilDate: selectReadingsUntilDate(state),
    detergentPod: selectModal(state)?.modalProps?.detergentPod,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      triggerSubmit: submit,
      hideDialog,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDetergentReadingDialog);
