import { PropTypes } from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

const ArchiveDetergentsForm = ({ text }) => {
  return (
    <div>
      <div className="form-group">
        <label>{text}</label>
      </div>
    </div>
  );
};

ArchiveDetergentsForm.propTypes = {
  text: PropTypes.string,
};

export default reduxForm({ form: 'detergentsForm' })(ArchiveDetergentsForm);
