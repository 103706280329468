import { findIndex, map } from 'lodash';
import moment from 'moment-timezone';
import { PurchaseOrder } from './models';

const from = sessionStorage.getItem('purchaseOrdersFrom');
const until = sessionStorage.getItem('purchaseOrdersUntil');

const tz = sessionStorage.getItem('timeZone');

const fromDate = moment.tz(from, tz).startOf('day');
const untilDate = moment.tz(until, tz).endOf('day');

const initialState = {
  fromDate: from ? fromDate : moment.tz(tz).subtract(90, 'days'),
  untilDate: until ? untilDate : moment.tz(tz).startOf('day'),
  formTotals: { itemCosts: [0], additionalCost: 0 },
  purchaseOrders: [],
  categories: [],
  vendors: [],
};

const setFromDate = (state, date) => {
  window.sessionStorage.setItem('purchaseOrdersFrom', date.format('YYYY-MM-DD'));
  return {
    ...state,
    fromDate: date,
  };
};

const setUntilDate = (state, date) => {
  window.sessionStorage.setItem('purchaseOrdersUntil', date.format('YYYY-MM-DD'));
  return {
    ...state,
    untilDate: date,
  };
};

const fetchPurchaseOrders = (state, response) => {
  const purchaseOrders = map(response.results, (pos) => new PurchaseOrder(pos));
  return {
    ...state,
    purchaseOrders,
    page: response.page,
    total: response.total,
    pageSize: response.pageSize,
  };
};

const fetchEditPurchaseOrder = (state, response) => {
  return {
    ...state,
    purchaseOrderInEdit: new PurchaseOrder(response),
  };
};

const changePurchaseOrderStatus = (state, response) => {
  const changedPO = new PurchaseOrder(response);
  const changeIndex = findIndex(state.purchaseOrders, { id: changedPO.id });
  const purchaseOrders = [...state.purchaseOrders];
  purchaseOrders[changeIndex] = changedPO;

  return {
    ...state,
    purchaseOrders,
  };
};

const fetchPurchaseOrderCategories = (state, response) => {
  return {
    ...state,
    categories: response.results,
  };
};

const fetchPurchaseOrderVendors = (state, response) => {
  return {
    ...state,
    vendors: response.results,
  };
};

const setSubTotal = (state, itemCosts) => {
  return {
    ...state,
    formTotals: {
      additionalCost: state.formTotals.additionalCost,
      itemCosts,
    },
  };
};

const setAdditionalCost = (state, additionalCost) => {
  return {
    ...state,
    formTotals: {
      itemCosts: state.formTotals.itemCosts,
      additionalCost,
    },
  };
};

const clearPurchaseOrderInEdit = (state) => {
  return {
    ...state,
    purchaseOrderInEdit: undefined,
  };
};

export const purchaseOrders = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PURCHASE_ORDER_FROM_DATE':
      return setFromDate(state, action.date);
    case 'SET_PURCHASE_ORDER_UNTIL_DATE':
      return setUntilDate(state, action.date);
    case 'FETCH_PURCHASE_ORDERS_SUCCESS':
      return fetchPurchaseOrders(state, action.response);
    case 'FETCH_PURCHASE_ORDER_SUCCESS':
      return fetchEditPurchaseOrder(state, action.response);
    case 'EDIT_PURCHASE_ORDER_SUCCESS':
      return fetchEditPurchaseOrder(state, action.response);
    case 'RECEIVE_PURCHASE_ORDER_SUCCESS' || 'UNRECEIVE_PURCHASE_ORDER_SUCCESS':
      return changePurchaseOrderStatus(state, action.response);
    case 'FETCH_PURCHASE_ORDER_CATEGORIES_SUCCESS':
      return fetchPurchaseOrderCategories(state, action.response);
    case 'FETCH_PURCHASE_ORDER_VENDORS_SUCCESS':
      return fetchPurchaseOrderVendors(state, action.response);
    case 'SET_FORM_SUBTOTAL':
      return setSubTotal(state, action.amount);
    case 'SET_FORM_ADDITIONAL_COST':
      return setAdditionalCost(state, action.amount);
    case 'CLEAR_PURCHASE_ORDER_IN_EDIT':
      return clearPurchaseOrderInEdit(state);
    case 'DELETE_PURCHASE_ORDER':
      return fetchPurchaseOrders(state, action.response);
    default:
      return state;
  }
};

export const selectTotal = (state) => {
  return state.purchaseOrders.total;
};

export const selectPurchaseOrders = (state) => {
  return state.purchaseOrders.purchaseOrders;
};

export const selectFromDate = (state) => {
  return state.purchaseOrders.fromDate;
};

export const selectUntilDate = (state) => {
  return state.purchaseOrders.untilDate;
};

export const selectPage = (state) => {
  return state.purchaseOrders.page;
};

export const selectPageSize = (state) => {
  return state.purchaseOrders.pageSize;
};

export const selectCategories = (state) => {
  return state.purchaseOrders.categories;
};

export const selectVendors = (state) => {
  return state.purchaseOrders.vendors;
};

export const selectSubTotal = (state) => {
  return state.purchaseOrders.formTotals.itemCosts;
};

export const selectAdditionalCost = (state) => {
  return state.purchaseOrders.formTotals.additionalCost;
};

export const selectPurchaseOrderInEdit = (state) => {
  return state.purchaseOrders.purchaseOrderInEdit;
};
