import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getDetergents,
  getDetergentReadings,
  setReadingsFromDate,
  setReadingsUntilDate,
} from '../actions';
import {
  selectDetergentReadings,
  selectSiteDetergents,
  selectReadingsFromDate,
  selectReadingsUntilDate,
} from '../reducer';
import EditDetergentTableContainer from './EditDetergentTableContainer';

export const EditDetergents = ({ detergentPod, handleBack, siteDetergents }) => {
  return (
    <EditDetergentTableContainer
      detergentPodId={detergentPod.id}
      detergentContainers={detergentPod?.detergentContainers}
      siteDetergents={siteDetergents}
      handleBack={handleBack}
    />
  );
};

EditDetergents.propTypes = {
  siteDetergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  detergentReadings: PropTypes.shape({}),
  fetchDetergents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  detergents: selectSiteDetergents(state),
  detergentReadings: selectDetergentReadings(state),
  readingsDates: {
    fromDate: selectReadingsFromDate(state),
    untilDate: selectReadingsUntilDate(state),
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDetergents: getDetergents,
      getReadings: getDetergentReadings,
      setFromDate: setReadingsFromDate,
      setUntilDate: setReadingsUntilDate,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(EditDetergents);
