import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import DateTimePicker from '../../../core/components/DateTimePicker';
import { errorInput, fieldRequired, requiredSelect, Select } from '../../../core/formValidation';
import { InventoryType } from '../models';

const InventoryEditForm = ({ intl, inventoryType }) => {
  const [type, setType] = useState(inventoryType);
  useEffect(() => {
    setType(inventoryType);
  }, [inventoryType]);
  return (
    <div className="form-group">
      <div className="form-group">
        <label htmlFor="type">{intl.formatMessage({ id: 'type' })}</label>
        <Field
          name="type"
          id="type"
          component={requiredSelect}
          className="form-control"
          parse={Number}
          onChange={(e) => setType(Number(e.target.value))}
        >
          <option value={InventoryType.Part}>{intl.formatMessage({ id: 'part' })}</option>
          <option value={InventoryType.Tool}>{intl.formatMessage({ id: 'tool' })}</option>
          <option value={InventoryType.Equipment}>{intl.formatMessage({ id: 'equipment' })}</option>
          <option value={InventoryType.Detergent}>{intl.formatMessage({ id: 'detergent' })}</option>
        </Field>
      </div>
      <div className="form-group">
        <label htmlFor="number">{intl.formatMessage({ id: 'partNumber' })}</label>
        <Field
          name="number"
          id="number"
          component={errorInput}
          type="text"
          className="form-control"
          placeholder="Enter part number"
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <label htmlFor="name">{intl.formatMessage({ id: 'name' })}</label>
        <Field
          name="name"
          id="name"
          component={errorInput}
          type="text"
          className="form-control"
          placeholder="Enter part name"
          validate={[fieldRequired]}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">{intl.formatMessage({ id: 'description' })}</label>
        <Field
          name="description"
          id="description"
          component="input"
          type="text"
          className="form-control"
          placeholder="Enter item description"
        />
      </div>
      {type !== InventoryType.Detergent && (
        <React.Fragment>
          <div className="form-group d-flex flex-column">
            <label htmlFor="installedAt" className="mr-2">
              {intl.formatMessage({ id: 'installedAt' })}
            </label>
            <Field
              name="installedAt"
              id="installedAt"
              component={DateTimePicker}
              className="form-control"
              placeholderText="Select installation date"
            />
          </div>
        </React.Fragment>
      )}
      <div className="form-group">
        <label htmlFor="preferredStockLevel">
          {intl.formatMessage({ id: 'preferredStockLevel' })}
        </label>
        <div style={{ display: 'flex' }}>
          <span>
            <Field
              name="preferredStockLevel"
              id="preferredStockLevel"
              component="input"
              type="number"
              className="form-control"
              parse={Number}
              placeholder="Enter preferred stock level"
            />
          </span>
          <span className="ml-4">
            <Field name="unitOfMeasure" id="unitOfMeasure" component={Select}>
              <option value="Gallons">{intl.formatMessage({ id: 'gallons' })}</option>
              <option value="Number">{intl.formatMessage({ id: 'number' })}</option>
              <option value="Feet">{intl.formatMessage({ id: 'feet' })}</option>
            </Field>
          </span>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="preferredStockLevel">{intl.formatMessage({ id: 'url' })}</label>
        <Field
          name="vendorUrl"
          id="vendorUrl"
          component="input"
          type="string"
          className="form-control"
          placeholder="Enter a link to the vendor"
        />
      </div>
    </div>
  );
};

InventoryEditForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  inventoryType: PropTypes.number,
};

const enhance = compose(reduxForm({ form: 'inventory' }), injectIntl);

export default enhance(InventoryEditForm);
