import { isEqual } from 'lodash/lang';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import DataRow from '../DataRow';
import DataTableHeaders from '../DataTableHeaders';
import Loader from '../Loader';
import Pagination from '../Pagination';
import CSVExport from '../CSVExport';
import SearchBar from '../SearchBar';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsWithSubRows: [],
      searchResults: null,
      searchResultsDetails: [],
      searchText: '',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
      return false;
    }
    return true;
  }

  getSubRows = (id) => {
    return this.props.subRowInfo.subRows
      .find((row) => row.parentRow === id)
      .rows.data.map((row, i) => <DataRow key={`${id}${i}`} data={row} isSubRow />);
  };

  getSummaryRow = () => {
    const maxNumberOfColumns = this.props.headers.length;
    const summary = this.props.summary;
    const cells = [];

    if (summary && summary.length > 0) {
      for (let i = 0; i < maxNumberOfColumns; i++) {
        const summaryCell = summary.find((e) => e.index === i);
        if (summaryCell) {
          cells.push(<td key={i}>{summaryCell.value}</td>);
        } else {
          cells.push(<td key={i} />);
        }
      }
    }

    return cells.length > 0 ? <tr>{cells}</tr> : null;
  };

  getSearchRow = () => {
    const maxNumberOfColumns = this.props.headers.length;
    const searchInfo = this.props.searchInfo;
    const cells = [];

    if (!searchInfo) {
      return null;
    }

    for (let i = 0; i < maxNumberOfColumns; i++) {
      const search = searchInfo.find((e) => e.index === i);

      if (search) {
        const colSpan = search.colSpan || 1;
        cells.push(
          <td className="table-search-input dt-sticky-search" colSpan={colSpan} key={i}>
            {search.element}
          </td>
        );
        // We need to subtract 1 since the i variable is increased at the start of the loop.
        // Otherwise we'll skip a column and the table looks all wrong
        i += colSpan - 1;
      } else {
        cells.push(<td key={i} className="dt-sticky-search" />);
      }
    }

    return <tr>{cells}</tr>;
  };

  getHeaderSelectProps = () => {
    return {
      data: this.props.data,
      selectedRows: this.props.selectedRows,
      updateSelectedRows: this.props.updateSelectedRows,
      multiSelectEnabled: this.multiSelectEnabled,
    };
  };

  toggleSubRows = (id) => {
    const subRowsAreShown = this.state.rowsWithSubRows.includes(id);

    if (this.props.subRowInfo) {
      if (!subRowsAreShown && this.props.subRowInfo.subRowFetchFunction) {
        this.props.subRowInfo.subRowFetchFunction(id);
      } else if (subRowsAreShown && this.props.subRowInfo.subRowRemovalFunction) {
        this.props.subRowInfo.subRowRemovalFunction(id);
      }
    }

    this.setState((prevState) => ({
      rowsWithSubRows: !subRowsAreShown
        ? prevState.rowsWithSubRows.concat(id)
        : prevState.rowsWithSubRows.filter((row) => row !== id),
    }));
  };

  setSearchMatch = (searchResults, searchResultsDetails, searchText) => {
    this.setState({
      searchResults,
      searchResultsDetails,
      searchText,
    });
  };

  parentRowExists(id) {
    if (!this.props.subRowInfo || !this.props.subRowInfo.subRows) {
      return null;
    }

    return this.props.subRowInfo.subRows.some((subRow) => subRow.parentRow === id);
  }

  multiSelectEnabled = this.props.options && this.props.options.isSelect;

  render() {
    const rowData = this.state.searchResults !== null ? this.state.searchResults : this.props.data;
    const rows = rowData.map((row) => {
      const { _id, name } = row;
      let highlighted = _id == this.props.highlightedRow;

      let identifier;
      let rowWithSubRows;
      let showSubRows;

      if (this.props.subRowInfo) {
        const displayProperty = this.props.subRowInfo.displayProperty;

        identifier = this.props.subRowInfo.identifier;
        showSubRows =
          this.state.rowsWithSubRows.includes(row[identifier]) &&
          this.parentRowExists(row[identifier]);

        if (showSubRows) {
          rowWithSubRows = { ...row };
          rowWithSubRows[displayProperty] = `${String.fromCharCode(65293)} ${row[displayProperty]}`;
          // SubRows are not shown but they still exist
        } else if (this.parentRowExists(row[identifier])) {
          rowWithSubRows = { ...row };
          rowWithSubRows[displayProperty] = `${String.fromCharCode(65291)} ${row[displayProperty]}`;
        }
      }

      const comps = {};
      this.props.headers.forEach((headerObj, columnPlacement) => {
        if (headerObj.cell) {
          comps[columnPlacement] = headerObj.cell;
        }
      });
      return [
        <DataRow
          key={_id || name || 0}
          data={rowWithSubRows || row}
          highlighted={highlighted}
          navigationInfo={this.props.navigationInfo}
          rowClickInfo={this.props.rowClickInfo}
          rowInEdit={this.props.rowInEdit}
          rowClicked={() => this.toggleSubRows(row[identifier])}
          setSelectedRow={this.multiSelectEnabled && this.props.updateSelectedRows}
          isChecked={
            this.props.multiSelectEnabled
              ? this.multiSelectEnabled &&
                (!!this.props.selectedRows[_id] || !!this.props.selectedRows[name])
              : false
          }
          rowComponents={comps}
        />,
        showSubRows && this.getSubRows(row[identifier]),
      ];
    });

    const summaryRow = this.getSummaryRow();
    const searchRow = this.getSearchRow();
    const { options, headers, toCSV, data } = this.props;
    const tableData =
      this.state.searchResults !== null ? this.state.searchResults : this.props.data;
    return (
      <React.Fragment>
        <div className="table-filters no-margin">
          {this.props.searchOptions && data && (
            <div className="nosubmit">
              <SearchBar
                intl={this.props.intl}
                setSearchMatch={this.setSearchMatch}
                uniqueField={this.props.searchOptions.uniqueField}
                dataToSearchOn={data}
                dataToSearchFor={this.props.searchOptions.dataToSearchFor}
              />
            </div>
          )}
          {toCSV && data && headers && (
            <CSVExport data={tableData} headers={headers} filename="export" />
          )}
        </div>
        <div
          data-testid="datatable"
          className={`table-responsive${this.props.scrolling ? ' dt-table-scroll' : ''} ${
            this.props?.datatable?.styles || ''
          }`}
        >
          <table className={`table ${this.props.addedClass ? this.props.addedClass : ''}`}>
            {this.props.colGroup && this.props.colGroup}
            <thead
              className="thead"
              style={options && options.styleHeader && { ...options.styleHeader }}
            >
              <DataTableHeaders
                headers={headers}
                fetchSortedData={this.props.fetchSortedData}
                isLoading={this.props.isLoading}
                headerSelectProps={this.getHeaderSelectProps()}
              />
            </thead>
            {!this.props.showLoader && (
              <tbody>
                {searchRow}
                {rows}
                {summaryRow}
              </tbody>
            )}
          </table>
          {this.props.showLoader && <Loader />}
        </div>
        <Pagination showLoader={!this.props.showLoader} info={this.props.paginationInfo} />
      </React.Fragment>
    );
  }
}

DataTable.defaultProps = {
  data: [],
  headers: [],
  summary: [],
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  options: PropTypes.shape({
    styleHeader: PropTypes.object,
    isSelect: PropTypes.bool,
  }),
  paginationInfo: PropTypes.shape({}),
  navigationInfo: PropTypes.shape({}),
  rowClickInfo: PropTypes.shape({}),
  subRowInfo: PropTypes.shape({
    subRows: PropTypes.arrayOf(PropTypes.object),
    displayProperty: PropTypes.string,
    identifier: PropTypes.string,
    subRowFetchFunction: PropTypes.func,
    subRowRemovalFunction: PropTypes.func,
  }),
  searchInfo: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      colSpan: PropTypes.number,
      element: PropTypes.any,
    })
  ),
  summary: PropTypes.arrayOf(PropTypes.object),
  addedClass: PropTypes.string,
  colGroup: PropTypes.shape({}),
  fetchSortedData: PropTypes.func,
  showLoader: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedRows: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  highlightedRow: PropTypes.number,
  updateSelectedRows: PropTypes.func,
  scrolling: PropTypes.bool,
  toCSV: PropTypes.bool,
  searchOptions: PropTypes.shape({
    uniqueField: PropTypes.string,
    dataToSearchFor: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default DataTable;
