import 'intl';
import IntlPolyfill from 'intl';
import areIntlLocalesSupported from 'intl-locales-supported';
// import IntlMessageFormat from 'intl-messageformat';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fi';
import { PropTypes } from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import getLocalizedMesssages from '../locales';

const localesMyAppSupports = ['en-US', 'fi-FI']; // Add the locales your app supports
const isIntlLocalesSupported = areIntlLocalesSupported(localesMyAppSupports);

if (isIntlLocalesSupported) {
  // Use the Intl API's
} else {
  // Load the Intl polyfill
  Intl.NumberFormat = IntlPolyfill.NumberFormat;
  Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
  Intl.PluralRules = IntlPolyfill.PluralRules;
  IntlMessageFormat.__addLocaleData(IntlPolyfill.__localeData);
}

const locale = navigator.language || navigator.browserLanguage;
const messages = getLocalizedMesssages(locale);

const IntlProviderWrapper = ({ children }) => {
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

IntlProviderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default IntlProviderWrapper;
