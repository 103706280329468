import { map } from 'lodash';
import { getMultiSites } from '../../core/storage';
import { selectSite } from '../../store/authReducer';
import { Site } from './models';
import { selectClosestSites } from './../overview/reducer';
import { useSelector } from 'react-redux';

const initialState = {
  sites: [],
  multiSiteSelection: [],
};

const fetchSitesSuccess = (state, { results }) => {
  const sites = results.filter((s) => s.id !== null && s.id !== undefined).map((s) => new Site(s));

  return {
    ...state,
    sites,
  };
};

const clearSiteSelections = (state) => {
  return {
    ...state,
    sites: [],
  };
};

const setMultiSiteSelection = (state, sites) => {
  return {
    ...state,
    multiSiteSelection: sites,
  };
};

export const siteSelection = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SITES_SUCCESS':
      return fetchSitesSuccess(state, action.response);
    case 'CLEAR_SITE_SELECTIONS':
      return clearSiteSelections(state);
    case 'SET_MULTI_SITE_SELECTION':
      return setMultiSiteSelection(state, action.sites);
    default:
      return state;
  }
};

export const selectSites = (state) => {
  const selectedSite = selectSite(state);
  const sites = state.siteSelection.sites;

  if (!selectedSite) {
    return sites;
  }

  return sites.map((site) => {
    const selected = site.id.toString() === selectedSite.toString();

    return {
      ...site,
      selected,
    };
  });
};

export const selectSitesBasic = (state) => {
  return state.siteSelection.sites;
};

export const selectSiteName = (state) => {
  return state.siteSettings.site.name;
};

export const selectMultiSiteNames = (state) => {
  return state.siteSelection.sites.map((site) => ({
    id: site.extId,
    name: site.externalId,
  }));
};

export const selectMultiSiteSelection = (state) => {
  return state.siteSelection.multiSiteSelection || [];
};

export const selectMultiSiteSelectionIds = (state) => {
  if (!state.siteSelection.multiSiteSelection.length) {
    return [];
  }

  return map(state.siteSelection.multiSiteSelection, (site) => {
    return site.id;
  });
};

export const selectMultiSiteSelectionExternalIds = (state) => {
  if (!state.siteSelection.multiSiteSelection.length) {
    let multiSites = getMultiSites();
    if (multiSites) {
      return map(JSON.parse(multiSites), (site) => {
        return site.externalId;
      });
    }
    return [];
  }

  return map(state.siteSelection.multiSiteSelection, (site) => {
    return site.externalId;
  });
};

export const selectMultiSiteSelectionCoreIds = (state) => {
  if (!state.siteSelection.multiSiteSelection.length) {
    let multiSites = getMultiSites();
    if (multiSites) {
      return map(JSON.parse(multiSites), (site) => {
        return site.extId;
      });
    }
    return [];
  }

  return map(state.siteSelection.multiSiteSelection, (site) => {
    return site.extId;
  });
};

export const selectMultiSiteSelectionManagerIds = (state) => {
  if (!state.siteSelection.multiSiteSelection.length) {
    let multiSites = getMultiSites();
    if (multiSites) {
      return map(JSON.parse(multiSites), (site) => {
        return site.id;
      });
    }
    return [];
  }

  return map(state.siteSelection.multiSiteSelection, (site) => {
    return site.id;
  });
};
export const selectClosestManagerIds = (state) => {
  let closestSites = selectClosestSites(state);
  const managerIds = closestSites.map((site) => site.managerId);
  if (managerIds) {
    return managerIds;
  }
  return [];
};
