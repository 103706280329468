import moment from 'moment';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { addPermissionRefs } from '../../../core/components/UserGroupPermissions';
import * as utils from '../../../core/utils';
import { selectSite } from '../../../store/authReducer';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import * as actions from '../actions';
import InventoryFilters from '../components/InventoryFilters';
import InventoryDetail from './InventoryDetail';
import { InventoryType } from '../models';
import {
  selectInventory,
  selectPage,
  selectPageSize,
  selectTotal,
  selectInventoryInEdit,
} from '../reducer';
import { Drawer, DrawerContainer } from '../../../core/components/Drawer';

const Inventory = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const inventory = useSelector(selectInventory);
  const currentSite = useSelector(selectSite).toString();
  const filter = query.types;
  const total = useSelector(selectTotal);
  const page = useSelector(selectPage);
  const pageSize = useSelector(selectPageSize);
  const siteId = useSelector(selectSiteExtId);
  const multiSites = useSelector(selectMultiSiteSelection);
  const [inventoryExpanded, setExpanded] = useState(false);
  const inventoryInEdit = useSelector(selectInventoryInEdit);

  useEffect(() => {
    props.fetchInventory();
  }, [filter, siteId, multiSites, inventoryInEdit]);

  const getInventoryPage = (selectedPage) => {
    props.fetchInventory({ selectedPage });
  };

  const getPaginationInfo = () => {
    return {
      total: total,
      page: page,
      pageSize: pageSize,
      goToPage: getInventoryPage,
    };
  };

  const editInventory = (event, { _id }) => {
    props.getInventoryItem(_id);
    const dialogProps = {
      title: intl.formatMessage({ id: 'editInventoryItem' }),
      onSubmit: props.saveInventoryItem,
      isNew: false,
    };
    props.showDialog('UPSERT_INVENTORY', dialogProps);
  };

  const deleteInventory = (event, { _id, name }) => {
    const confirmProps = {
      onReady: props.deleteInventoryItem.bind(null, { id: _id }),
      text: intl.formatMessage({ id: 'inventoryItemArchiveConfirmation' }, { name }),
      title: intl.formatMessage({ id: 'archiveInventoryItem' }),
    };
    props.showDialog('CONFIRM_DIALOG', confirmProps);
  };

  const getRowClickInfo = () => ({
    callback: (data) => {
      setExpanded(true);
      dispatch(actions.getInventoryItem(data._id));
    },
  });

  const initializeDatatable = (initialInventory) => {
    const data = initialInventory.map((i) => {
      return {
        _id: i.id,
        type: utils.toString(InventoryType, i.type),
        partNumber: i.number,
        name: i.name,
        description: i.description,
        installedAt: moment(i.installedAt).format('MM/DD/YYYY h:mm:ss a'),
        preferredStockLevel: i.preferredStockLevel,
        unit: i?.unitOfMeasure || '',
      };
    });

    const headers = [
      { name: intl.formatMessage({ id: 'type' }) },
      { name: intl.formatMessage({ id: 'partNumber' }) },
      { name: intl.formatMessage({ id: 'name' }) },
      { name: intl.formatMessage({ id: 'description' }) },
      { name: intl.formatMessage({ id: 'installedAt' }) },
      { name: intl.formatMessage({ id: 'inStock' }) },
      { name: intl.formatMessage({ id: 'unit' }) },
      {
        name: intl.formatMessage({ id: 'action' }),
        cell: {
          component: TableActionButtonsCell,
          mixIns: {
            buttons: [
              { icon: 'icon-trash', buttonAction: deleteInventory },
              { icon: 'icon-edit', buttonAction: editInventory },
            ],
          },
        },
      },
    ];

    return {
      data,
      rowClickInfo: getRowClickInfo(),
      headers,
    };
  };

  const drawerControlsConfig = () => {
    return {
      closeFunction: () => {
        setExpanded(false);
        dispatch(actions.deleteInventoryInEdit());
      },
    };
  };

  const addDialogProps = {
    onSubmit: props.addInventoryItem,
    isNew: true,
    title: intl.formatMessage({ id: 'addInventoryItem' }),
  };

  return (
    <div className="container-fluid">
      <section className="page-main-content inventory">
        <div className="table-buttons">
          <InventoryFilters currentSite={currentSite} selectedFilter={filter} />
          <PageActions
            actionFunction={props.showDialog}
            actions={[
              {
                dialogType: 'UPSERT_INVENTORY',
                dialogProps: addDialogProps,
                text: intl.formatMessage({ id: 'addInventoryItem' }),
                permissionKey: addPermissionRefs.inventory,
              },
            ]}
          />
        </div>
        <DrawerContainer>
          <div>
            <DataTable
              paginationInfo={getPaginationInfo()}
              scrolling
              {...initializeDatatable(inventory)}
            />
          </div>
          <Drawer expanded={inventoryExpanded}>
            <div className="inventory-details-wrapper">
              {inventoryInEdit && (
                <InventoryDetail isPage={false} drawerControls={{ ...drawerControlsConfig() }} />
              )}
            </div>
          </Drawer>
        </DrawerContainer>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, showDialog }, dispatch);
};

export default connect(null, mapDispatchToProps)(Inventory);
