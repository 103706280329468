import moment from 'moment';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { showDialog } from '../../../core/actions';
import ContentTabs from '../../../core/components/ContentTabs';
import DetailHeader from '../../../core/components/DetailHeader';
import { DetailButtonGroup } from '../../../core/components/DetailButtonGroup';
import {
  deletePermissionRefs,
  editPermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import { momentInMMDDYYYYFormat } from '../../../core/utils';
import {
  deleteInventoryInEdit,
  deleteInventoryItem,
  getInventoryItem,
  saveInventoryItem,
} from '../actions';
import { InventoryType } from '../models';
import { selectInventoryInEdit } from '../reducer';
import DetergentInventoryData from './DetergentInventoryData';

const InventoryDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const inventoryInEdit = useSelector(selectInventoryInEdit);

  useEffect(() => {
    if (props.isPage) {
      const id = params.inventory;
      dispatch(getInventoryItem(id));
    }
  }, [params.inventory]);

  const getTitles = (inventory) => {
    const formatMessage = intl.formatMessage;
    return [
      {
        className: 'detail-name',
        label: formatMessage({ id: 'name' }),
        content: inventory.name,
      },
      {
        className: 'detail-id',
        label: formatMessage({ id: 'id' }),
        content: inventory.id,
      },
      {
        label: formatMessage({ id: 'partNumber' }),
        content: inventory.number,
      },
      {
        label: formatMessage({ id: 'installationDate' }),
        content: inventory.installedAt
          ? momentInMMDDYYYYFormat(moment(inventory.installedAt))
          : formatMessage({ id: 'notAvailable' }),
      },
      {
        label: formatMessage({ id: 'inStock' }),
        content: inventory.preferredStockLevel || formatMessage({ id: 'notAvailable' }),
      },
      {
        label: formatMessage({ id: 'unit' }),
        content: inventory?.unitOfMeasure || formatMessage({ id: 'notAvailable' }),
      },
      {
        label: formatMessage({ id: 'url' }),
        content: formatMessage({ id: 'notAvailable' }),
        children: (
          <div key={formatMessage({ id: 'url' })} className="detail-url">
            <label>{formatMessage({ id: 'url' })}</label>
            <a href={inventory.vendorUrl}>{inventory.vendorUrl}</a>
          </div>
        ),
      },
    ];
  };

  const editInventoryItem = () => {
    const saveInventoryWithCallBack = (item) => {
      dispatch(
        saveInventoryItem({
          ...item,
          callback: () => getInventoryItem(inventoryInEdit.id),
        })
      );
    };
    const confirmProps = {
      title: 'Edit Inventory',
      onSubmit: saveInventoryWithCallBack,
      isNew: false,
    };
    dispatch(showDialog('UPSERT_INVENTORY', confirmProps));
  };

  const handleDeleteInventoryItem = () => {
    const { id, name } = inventoryInEdit;
    const { site } = params;

    const query = {
      id,
      navigate: () => {
        history.push(`/${site}/inventory`);
      },
    };
    const confirmProps = {
      onReady: () => {
        dispatch(deleteInventoryItem(query));
        dispatch(deleteInventoryInEdit());
        if (!props.isPage) {
          props.drawerControls.closeFunction();
        }
      },
      text: `Are you sure you want to archive inventory: "${name}" ?`,
      title: 'Archive Inventory',
    };
    dispatch(showDialog('CONFIRM_DIALOG', confirmProps));
  };

  const inventory = inventoryInEdit;
  const components = { Description: <div>{inventory.description}</div> };

  if (inventoryInEdit.type === InventoryType.Detergent) {
    components['Detergent Inventory Data'] = <DetergentInventoryData inventory={inventory} />;
  }

  const buttons = [
    {
      onClick: handleDeleteInventoryItem,
      label: intl.formatMessage({ id: 'delete' }),
      class: 'button primary inverted mr-2',
      permissionKey: deletePermissionRefs.inventory,
    },
    {
      onClick: editInventoryItem,
      label: intl.formatMessage({ id: 'edit' }),
      permissionKey: editPermissionRefs.inventory,
    },
  ];

  return (
    <>
      <DetailButtonGroup
        history={history}
        intl={intl}
        buttons={buttons}
        isPage={props.isPage}
        drawerControls={props.drawerControls}
      />
      <DetailHeader addedClass="inventory-detail" titles={getTitles(inventory)} />
      <ContentTabs components={components} />
    </>
  );
};

export default InventoryDetail;
