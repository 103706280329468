import { get, map } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit, reset } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import { checkErrors, checkVideoUrl } from '../../../core/formValidation';
import {
  selectSiteModelYears,
  selectSiteOperationTypes,
  selectSiteTotalCarCount,
} from '../../settings/reducer';
import { dependencies } from '../../maintenanceTasks/models';
import { selectEmployees } from '../../employees/reducer';
import { generateToastr } from '../../../core/toastMessages';
import TaskEditForm from '../components/TaskEditForm';
import { Interval, TaskType } from '../models';
import {
  selectTaskInEdit,
  selectTaskLocations,
  selectTaskPrefixes,
  selectTasks,
  selectMaintenanceTaskInEdit,
} from '../reducer';
import { getTaskPrefixes } from '../actions';
import TaskModalDialog from '../../../core/components/TaskModal';
import { getSiteModelYears, getSiteOperationTypes } from '../../settings/actions';
import { selectRole } from '../../../store/authReducer';
import { RoleTypes } from '../../../core/components/Permission';

const initialFormValues = {
  start: moment(),
  weeklySchedule: moment().day().toString(),
  monthlySameWeekday: false,
  alertIfOverdue: true,
  type: TaskType.Maintenance,
  interval: Interval.CarCount,
  intervalCount: 1,
  hourlySchedule: [],
  prefixId: 0,
  carCount: 0,
  linkedTasks: [],
};

class UpsertTaskDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    triggerReset: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    initialTask: PropTypes.shape({
      startingCarCount: PropTypes.number,
      carCount: PropTypes.number,
    }),
    isNew: PropTypes.bool,
    isEssentialTask: PropTypes.bool,
    title: PropTypes.string.isRequired,
    errors: PropTypes.shape({}),
    location: PropTypes.shape({}),
    totalCarCount: PropTypes.number,
    fetchTaskPrefixes: PropTypes.func,
    fetchOperationTypes: PropTypes.func,
    fetchSiteModelYears: PropTypes.func,
    taskPrefixList: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        message: PropTypes.string,
      })
    ),
    taskLocationList: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        message: PropTypes.string,
        orderNumber: PropTypes.number,
      })
    ),
    employees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    siteOperationTypeList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    siteModelYearList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.string,
      })
    ),
    modelYears: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.string,
      })
    ),
  };

  state = {
    selectedTasks: [],
    selectedDependencies: [],
  };

  handleTaskSelect = (selectedTasks) => {
    this.setState({ selectedTasks });
  };

  handleDependencySelect = (selectedDependencies) => {
    this.setState({ selectedDependencies });
  };

  onReady = () => {
    this.props.triggerSubmit('task');
  };

  onReset = () => {
    this.props.triggerReset('task');
  };

  componentDidMount() {
    const oga = this.props.role >= RoleTypes.OperatorGroupAdmin;
    if (!this.props.taskPrefixList.length) {
      this.props.fetchTaskPrefixes();
    }

    if (!this.props.siteOperationTypeList.length && oga) {
      this.props.fetchOperationTypes();
    }

    if (!this.props.siteModelYearList.length && oga) {
      this.props.fetchSiteModelYears();
    }
  }

  onSubmit = (value) => {
    const task = _.cloneDeep(value);
    const intervalCarCount = task.repeatTask ? task.intervalCarCount : 0;
    task.videos = get(task, 'videos', []);
    task.linkedTasks = get(task, 'linkedTasks', []);

    // check for valid video urls
    const videoErrorCheck = [];
    if (task.videos.length) {
      _.compact(_.flatten(task.videos));
      task.videos = map(task.videos, (video) => {
        const videoCheck = checkVideoUrl(video);
        if (videoCheck === '' || videoCheck === undefined || videoCheck === 'Invalid Video URL') {
          videoErrorCheck.push(video);
        } else {
          return videoCheck;
        }
      });
    }
    if (videoErrorCheck.length) {
      map(videoErrorCheck, (video) => {
        generateToastr('error', 'Invalid Video URL:', video, { timeOut: 5000 });
      });
      return;
    }

    if (task.linkedTasks.length) {
      task.linkedTasks = _.map(task.linkedTasks, (linkedTask) => {
        return { id: linkedTask.linkedTask.value, dependency: linkedTask.dependency.value };
      });
    }

    if (task.interval === Interval.CarCount) {
      this.props.onSubmit({
        ...task,
        intervalCarCount,
        carCount:
          this.props.initialTask.startingCarCount !== task.startingCarCount
            ? task.startingCarCount
            : this.props.initialTask.carCount,
      });
    } else {
      this.props.onSubmit({
        ...task,
        intervalCarCount,
        startingCarCount: null,
      });
    }
  };

  render() {
    const {
      closeDialog,
      isOpen,
      initialTask,
      isNew,
      modelYears,
      totalCarCount,
      isEssentialTask,
      taskPrefixList,
      taskLocationList,
      siteOperationTypeList,
      siteModelYearList,
      employees,
    } = this.props;

    const enableReinitialize = !isNew;
    const disabled = checkErrors(this.props.errors);

    const taskNameLookup = (taskId) => {
      return this.props.tasks.find((t) => t.id === taskId)?.name;
    };

    const initialTaskModified = {
      ...initialTask,
      startingCarCount: initialTask.startingCarCount || totalCarCount,
      linkedTasks: _.map(initialTask.linkedTasks, (linkedTask) => {
        let dependency = dependencies.find((t) => t.value === linkedTask.dependency);
        return {
          linkedTask: {
            value: linkedTask.linkedTaskId,
            name: taskNameLookup(linkedTask.linkedTaskId),
          },
          dependency: { value: dependency?.id, name: dependency?.name },
        };
      }),
    };

    let taskModelYears;
    if (!isNew && modelYears && modelYears.length > 0) {
      taskModelYears = modelYears.map((modelYear) => {
        return {
          yearId: modelYear.id,
        };
      });
    }

    return (
      <TaskModalDialog
        className="enhanced-dialog"
        title={this.props.title}
        onReady={this.onReady}
        onReset={this.onReset}
        isOpen={isOpen}
        isNew={isNew}
        close={closeDialog}
        disabled={disabled}
      >
        <TaskEditForm
          className="enhanced-dialog"
          totalCarCount={totalCarCount}
          onSubmit={(values) => this.onSubmit(values)}
          tasks={this.props.tasks}
          selectedTasks={this.state.selectedTasks}
          selectedDependencies={this.state.selectedDependencies}
          handleTaskSelect={this.handleTaskSelect}
          handleDependencySelect={this.handleDependencySelect}
          initialValues={initialTaskModified}
          enableReinitialize={enableReinitialize}
          hours={initialTask.hourlySchedule}
          isEssentialTask={isEssentialTask}
          taskPrefixes={taskPrefixList}
          taskLocations={taskLocationList}
          siteOperationTypes={siteOperationTypeList}
          siteModelYears={siteModelYearList}
          modelYears={taskModelYears}
          employees={employees}
          isNew={isNew}
        />
      </TaskModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('task');

const mapStateToProps = (state, ownProps) => {
  let initialTask = initialFormValues;
  if (!ownProps.isNew) {
    if (ownProps.location?.pathname.includes('maintenance')) {
      initialTask = selectMaintenanceTaskInEdit(state) || ownProps.initialTask;
    } else {
      initialTask = selectTaskInEdit(state) || ownProps.initialTask;
    }
  }

  const errors = getErrors(state);
  return {
    initialTask,
    errors,
    tasks: selectTasks(state),
    totalCarCount: selectSiteTotalCarCount(state),
    taskPrefixList: selectTaskPrefixes(state),
    taskLocationList: selectTaskLocations(state),
    siteModelYearList: selectSiteModelYears(state),
    siteOperationTypeList: selectSiteOperationTypes(state),
    employees: selectEmployees(state),
    role: selectRole(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
      triggerReset: reset,
      fetchTaskPrefixes: getTaskPrefixes,
      fetchOperationTypes: getSiteOperationTypes,
      fetchSiteModelYears: getSiteModelYears,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertTaskDialog);
