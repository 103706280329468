import React from 'react';
import CustomSelectInput from '../../core/components/CustomSelectInput';
import Detergent from './models';
import ColorPicker from '../../core/components/ColorPicker';

interface WashSelectionProps {
  products: any;
  intl: any;
  handleProductChange: any;
  selectedProducts: any;
  meta?: boolean;
}

interface DetergentSelectionProps {
  detergents: Detergent[];
  intl: any;
  handleDetergentChange: any;
  selectedDetergent: any;
  initialValues?: any;
  meta?: any;
  multi?: boolean;
}

interface ColorSelectProps {
  input: any;
}

export const colorSelect = ({ input }: ColorSelectProps): JSX.Element => {
  const predefinedColors = ['#00d563', '#3cb6e2', '#ffb326', '#cf26b4', '#ff8027', '#B700D5'];
  if (input.value === '') {
    input.onChange(predefinedColors[0]);
  }
  return (
    <div>
      <ColorPicker
        predefinedColors={predefinedColors}
        value={input.value}
        onChange={input.onChange}
      />
    </div>
  );
};

export const washSelection = (props: WashSelectionProps): JSX.Element => (
  <CustomSelectInput
    options={props.products.map((product: any) => ({ value: product.id, label: product.name }))}
    multi={true}
    placeholder={props.intl.formatMessage({ id: 'selectPlan' })}
    onChange={props.handleProductChange}
    defaultValue={props.selectedProducts ? props.selectedProducts : []}
    meta={props?.meta}
  />
);
export const detergentSelection = (props: DetergentSelectionProps): JSX.Element => {
  return (
    <CustomSelectInput
      options={props.detergents.map((detergent: any) => ({
        value: detergent.id,
        label: detergent.name,
      }))}
      placeholder={props.intl.formatMessage({ id: 'chooseDetergent' })}
      meta={props.meta}
      onChange={(value) => {
        if (value) props.handleDetergentChange(value);
      }}
      defaultValue={props?.initialValues ?? []}
      multi={props?.multi || false}
    />
  );
};
