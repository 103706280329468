import { map } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import { checkErrors } from '../../../core/formValidation';
import * as actions from '../actions';
import DetergentEditForm from '../components/DetergentEditForm';
import { selectDetergentInEdit } from '../reducer';
import DetergentModalDialog from '../../../core/components/DetergentModal';

class EditDetergentDialog extends Component {
  static propTypes = {
    saveDetergent: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    hideDialog: PropTypes.func.isRequired,
    initialDetergent: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    inventory: PropTypes.arrayOf(PropTypes.shape({})),
    detergents: PropTypes.arrayOf(PropTypes.shape({})),
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDetergent: null,
      selectedProducts: [],
    };
  }

  onReady = () => {
    this.props.triggerSubmit('detergent');
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialDetergent !== prevProps.initialDetergent) {
      this.setState({
        selectedDetergent: {
          value: this.props.initialDetergent?.detergentId || '',
          label: this.props.initialDetergent?.detergent.name || '',
        },
        selectedProducts: map(this.props.initialDetergent?.products, (product) => ({
          value: product.id,
          label: product.name,
        })),
      });
    }
  }

  onSubmit = (values) => {
    if (!values.reorderLevel) {
      values.reorderLevel = '0';
    }
    const formValues = {
      ...values,
      detergentId: parseInt(this.state.selectedDetergent.value, 10),
      products: map(this.state.selectedProducts, (product) => ({ id: product.value })),
      detergentContainerId: this.props.detergentContainerId,
    };
    this.props.upsertDetergentContainer(formValues);
    this.props.hideDialog();
  };

  handleDetergentChange = (selectedDetergent) => {
    this.setState({ selectedDetergent });
  };

  handleProductChange = (selectedProducts) => {
    this.setState({ selectedProducts });
  };

  componentWillUnmount() {
    this.props.clearDetergentSelections();
  }

  render() {
    const { isOpen, initialDetergent, products, inventory, addNew, detergents } = this.props;
    const submitDisabled = checkErrors(this.props.errors);

    if (initialDetergent) {
      if (initialDetergent.inventory) {
        initialDetergent.inventoryItem = initialDetergent.inventory.id;
      } else {
        initialDetergent.inventoryItem = -1;
      }
    }

    return (
      <DetergentModalDialog
        className="detergent-dialog"
        title={this.props.title}
        onReady={this.onReady}
        isOpen={isOpen}
        close={this.props.hideDialog}
        disabled={submitDisabled}
      >
        <DetergentEditForm
          onSubmit={this.onSubmit}
          initialValues={initialDetergent}
          products={products}
          inventory={inventory}
          detergents={detergents}
          selectedDetergent={this.state.selectedDetergent ?? []}
          handleDetergentChange={this.handleDetergentChange}
          handleProductChange={this.handleProductChange}
          addNew={addNew}
          selectedProducts={
            initialDetergent
              ? map(initialDetergent.products, (product) => ({
                  value: product.id,
                  label: product.name,
                }))
              : []
          }
          enableReinitialize
        />
      </DetergentModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('detergent');

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  return {
    initialDetergent: selectDetergentInEdit(state),
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      triggerSubmit: submit,
      hideDialog,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDetergentDialog);
