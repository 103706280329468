import moment from 'moment';
import { map, sortBy } from 'lodash';
import Detergent, { DetergentPod, DetergentContainer } from './models';

export const initialState = {
  siteDetergents: [],
  detergentPods: [],
  detergentData: [],
  costPerCarData: [],
  error: null,
  detergentInEdit: null,
  summaryChart: 'detergentLevels',
  readingsFromDate: moment().startOf('day'),
  readingsUntilDate: moment().endOf('day'),
  detergentReadings: {},
  detergentDataFromDate: moment().startOf('day'),
  detergentDataUntilDate: moment().endOf('day'),
  selectedDetergents: {},
};

const getDetergentsSuccess = (state, action) => {
  const siteDetergents = action.results.map((d) => new Detergent(d));
  const detergentData = Detergent.mapChartData(siteDetergents);
  return {
    ...state,
    siteDetergents,
    detergentData,
    error: null,
    page: action.page,
    total: action.total,
    pageSize: action.pageSize,
  };
};

const getDetergentSuccess = (state, detergent) => {
  const container = new DetergentContainer(detergent);
  return {
    ...state,
    detergentInEdit: container,
  };
};

const getChartDataSuccess = (state, response) => {
  return {
    ...state,
    costPerCarData: response.results,
  };
};

const getDetergentReadingsSuccess = (state, response) => {
  return {
    ...state,
    detergentReadings: {
      ...state.detergentReadings,
      ...response.results,
    },
  };
};

const showSummaryChart = (state, summaryChart) => {
  return {
    ...state,
    summaryChart,
  };
};

const setCostPerCarChartFromDate = (state, date) => {
  return {
    ...state,
    detergentDataFromDate: date,
  };
};

const setCostPerCarChartUntilDate = (state, date) => {
  return {
    ...state,
    detergentDataUntilDate: date,
  };
};

const setReadingsFromDate = (state, date) => {
  return {
    ...state,
    readingsFromDate: date,
  };
};

const setReadingsUntilDate = (state, date) => {
  return {
    ...state,
    readingsUntilDate: date,
  };
};

const setDetergentSelection = (state, action) => {
  return {
    ...state,
    selectedDetergents: action.rows,
  };
};

const removeReadings = (state, id) => {
  const detergentReadings = { ...state.detergentReadings };

  delete detergentReadings[id];

  return { ...state, detergentReadings };
};

const setDetergentPods = (state, response) => {
  const detergentPods = map(response.results, (pod) => new DetergentPod(pod));
  detergentPods.forEach((pod) => {
    pod.detergentData = DetergentPod.mapChartData(pod.detergents);
  });
  return {
    ...state,
    detergentPods: sortBy(detergentPods, 'title'),
  };
};

const clearDetergentSelections = (state) => {
  return {
    ...state,
    selectedDetergents: {},
    detergentInEdit: null,
  };
};

export const detergents = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DETERGENTS_SUCCESS':
      return getDetergentsSuccess(state, action.response);
    case 'GET_DETERGENT_SUCCESS':
      return getDetergentSuccess(state, action.response);
    case 'GET_CHART_DATA_SUCCESS':
      return getChartDataSuccess(state, action.response);
    case 'SET_COST_PER_CAR_CHART_FROM_DATE':
      return setCostPerCarChartFromDate(state, action.date);
    case 'SET_COST_PER_CAR_CHART_UNTIL_DATE':
      return setCostPerCarChartUntilDate(state, action.date);
    case 'GET_DETERGENT_READINGS_SUCCESS':
      return getDetergentReadingsSuccess(state, action.response);
    case 'SHOW_DETERGENT_SUMMARY_CHART':
      return showSummaryChart(state, action.chart);
    case 'SET_DETERGENT_READINGS_FROM_DATE':
      return setReadingsFromDate(state, action.date);
    case 'SET_DETERGENT_READINGS_UNTIL_DATE':
      return setReadingsUntilDate(state, action.date);
    case 'REMOVE_DETERGENT_READINGS':
      return removeReadings(state, action.id);
    case 'SET_DETERGENT_SELECTION':
      return setDetergentSelection(state, action);
    case 'GET_DETERGENT_PODS_SUCCESS':
      return setDetergentPods(state, action.response);
    case 'CLEAR_DETERGENT_SELECTIONS':
      return clearDetergentSelections(state);
    default:
      return state;
  }
};

export const selectSiteDetergents = (state) => {
  return state.detergents.siteDetergents;
};

export const selectDetergentData = (state) => {
  return state.detergents.detergentData;
};

export const selectDetergentReadings = (state) => {
  return state.detergents.detergentReadings;
};

export const selectDetergentInEdit = (state) => {
  return state.detergents.detergentInEdit;
};

export const selectSummaryChart = (state) => {
  return state.detergents.summaryChart;
};

export const selectTotal = (state) => {
  return state.detergents.total;
};

export const selectPage = (state) => {
  return state.detergents.page;
};

export const selectPageSize = (state) => {
  return state.detergents.pageSize;
};

export const selectReadingsFromDate = (state) => {
  return state.detergents.readingsFromDate;
};

export const selectReadingsUntilDate = (state) => {
  return state.detergents.readingsUntilDate;
};

export const selectCostPerCarChartFromDate = (state) => {
  return state.detergents.detergentDataFromDate;
};

export const selectCostPerCarChartUntilDate = (state) => {
  return state.detergents.detergentDataUntilDate;
};

export const selectCostPerCarData = (state) => {
  return state.detergents.costPerCarData;
};

export const selectSelectedDetergents = (state) => {
  return state.detergents.selectedDetergents;
};

export const selectDetergentPods = (state) => {
  return state.detergents.detergentPods;
};

export const selectDetergentContainers = (state) => {
  return state.detergents.detergentPods.map((pod) => pod.detergentContainers);
};

export const selectDetergentPodDetergents = (state, detergentPodId) => {
  return state.detergents.detergentPods.find((pod) => pod.id === detergentPodId)?.detergents || [];
};
