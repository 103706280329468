import React, { useEffect } from 'react';
import ModalDialog from '../../../../core/components/Modal';
import { DetergentPod } from '../../../detergents/models';
import { useIntl } from 'react-intl';
import ButtonWithLoader from '../../../../core/components/ButtonWithLoader';
import { useDispatch } from 'react-redux';
import {
  saveDetergentPod,
  addDetergentPod,
  archiveDetergentPod,
} from '../../../detergents/actions';

interface Props {
  title: string;
  onSubmit: () => void;
  isNew: boolean;
  isOpen: boolean;
  hide: boolean;
  detergentPod?: DetergentPod | undefined;
}

const UpsertDetergentPodDialog = (props: Props): JSX.Element => {
  const intl: any = useIntl();
  const dispatch = useDispatch();
  const [counter, setCounter] = React.useState<number>(4);
  const [showDeleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [detergentPodForm, setDetergentPodForm] = React.useState<{
    title: string;
    description: string;
  }>({
    title: '',
    description: '',
  });
  useEffect(() => {
    if (!props.isNew) {
      setDetergentPodForm({
        title: props?.detergentPod?.title || '',
        description: props?.detergentPod?.description || '',
      });
    }
  }, []);

  useEffect(() => {
    const timer = counter > 0 ? setInterval(() => setCounter(counter - 1), 1000) : 0;
    return () => clearInterval(timer);
  }, [counter]);

  const checkFormValues = () => {
    if (detergentPodForm.title === '') {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (props.isNew) {
      dispatch(addDetergentPod(detergentPodForm));
    } else {
      dispatch(saveDetergentPod(detergentPodForm, props?.detergentPod?.id));
    }
  };

  const handleDeleteModal = () => {
    setDeleteModal(!showDeleteModal);
    setCounter(4);
  };

  const handleDelete = () => {
    dispatch(archiveDetergentPod(props?.detergentPod?.id));
  };

  const handleTitle = (event: React.ChangeEvent<any>) => {
    setDetergentPodForm({ ...detergentPodForm, title: event.target.value });
  };
  const handleDescription = (event: React.ChangeEvent<any>) => {
    setDetergentPodForm({ ...detergentPodForm, description: event.target.value });
  };
  return (
    <ModalDialog title={props.title} isOpen={props.isOpen} close={props.hide} showFooter={false}>
      {!showDeleteModal ? (
        <div>
          <div>
            <div className="chart-title">{intl.formatMessage({ id: 'title' })}</div>
            <input
              placeholder="Enter Detergent Pod Name"
              className="detergent-input-area"
              onKeyUp={handleTitle}
              defaultValue={detergentPodForm.title}
            />
          </div>
          <div className="mt-3">
            <div className="chart-title">{intl.formatMessage({ id: 'description' })}</div>
            <div>
              <textarea
                placeholder="What is this pod used for?"
                className="detergent-text-area"
                onKeyUp={handleDescription}
                defaultValue={detergentPodForm.description}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {!props.isNew && (
              <ButtonWithLoader
                handleClick={handleDeleteModal}
                style="detergent-action-button-white mr-2 w-100"
              >
                <span>{intl.formatMessage({ id: 'deletePod' })}</span>
              </ButtonWithLoader>
            )}
            <ButtonWithLoader
              disabled={!checkFormValues()}
              handleClick={handleSubmit}
              style={
                checkFormValues()
                  ? 'detergent-action-button w-100'
                  : 'detergent-disabled-action-button w-100'
              }
            >
              <span>
                {props.isNew
                  ? intl.formatMessage({ id: 'saveNewPod' })
                  : intl.formatMessage({ id: 'save' })}
              </span>
            </ButtonWithLoader>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              textAlign: 'center',
              marginBottom: '15px',
              padding: '50px',
              fontSize: 'large',
            }}
          >
            {intl.formatMessage({ id: 'deletePodConfirmation' })}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonWithLoader
              handleClick={handleDeleteModal}
              style="detergent-action-button-white mr-2 w-100"
            >
              <span>{intl.formatMessage({ id: 'goBack' })}</span>
            </ButtonWithLoader>
            <ButtonWithLoader
              disabled={counter > 0 ? true : false}
              handleClick={handleDelete}
              style={
                counter === 0
                  ? 'detergent-action-button w-100'
                  : 'detergent-disabled-action-button w-100'
              }
            >
              <span>{counter > 0 ? counter : intl.formatMessage({ id: 'deleteForever' })}</span>
            </ButtonWithLoader>
          </div>
        </div>
      )}
    </ModalDialog>
  );
};

export default UpsertDetergentPodDialog;
