import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import * as actions from '../actions';
import InventoryEditForm from '../components/InventoryEditForm';
import { InventoryType } from '../models';
import { selectInventoryInEdit } from '../reducer';

const initialFormValues = {
  type: 0,
  number: null,
  name: null,
  description: null,
  installedAt: null,
  stockLevel: null,
  vendorUrl: null,
};

class UpsertUtilityDialog extends Component {
  static propTypes = {
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    initialInventory: PropTypes.shape({}).isRequired,
    onSubmit: PropTypes.func.isRequired,
    isNew: PropTypes.bool,
    title: PropTypes.string.isRequired,
    errors: PropTypes.shape({}),
  };

  onReady = () => {
    this.props.triggerSubmit('inventory');
  };

  onSubmit = (item) => {
    const newItem = { ...item };
    // Make sure that the URL is a somewhat working one
    if (item.vendorUrl) {
      const hasHTTP =
        item.vendorUrl.slice(0, 7) === 'http://' || item.vendorUrl.slice(0, 8) === 'https://';
      newItem.vendorUrl = hasHTTP ? item.vendorUrl : `https://${item.vendorUrl}`;
    }
    this.props.onSubmit({ item: newItem });
  };

  render() {
    const { closeDialog, isOpen, initialInventory, isNew } = this.props;
    const enableReinitialize = !isNew;
    const submitDisabled = checkErrors(this.props.errors);

    return (
      <ModalDialog
        title={this.props.title}
        onReady={this.onReady}
        isOpen={isOpen}
        close={closeDialog}
        disabled={submitDisabled}
      >
        <InventoryEditForm
          onSubmit={this.onSubmit}
          initialValues={initialInventory}
          inventoryType={initialInventory ? initialInventory.type : InventoryType.Part}
          enableReinitialize={enableReinitialize}
        />
      </ModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('inventory');

const mapStateToProps = (state, ownProps) => {
  const initialInventory = ownProps.isNew ? initialFormValues : selectInventoryInEdit(state);
  const errors = getErrors(state);
  return {
    initialInventory,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions, closeDialog: hideDialog, triggerSubmit: submit },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertUtilityDialog);
