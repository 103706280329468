import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { omit, keyBy } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { toString } from '../../../core/utils';
import { selectRole } from '../../../store/authReducer';
import { fetchInventory } from '../../inventory/actions';
import { InventoryType } from '../../inventory/models';
import { selectInventory } from '../../inventory/reducer';
import { fetchProducts } from '../../products/actions';
import { selectProducts } from '../../products/reducer';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { deleteDetergent, getDetergent, setDetergentSelection } from '../actions';
import {
  selectDetergentPods,
  selectSiteDetergents,
  selectPage,
  selectPageSize,
  selectSelectedDetergents,
  selectTotal,
} from '../reducer';

export const DetergentTableContainer = ({ ...props }) => {
  const [selectedDetergent, setSelectedDetergent] = useState({});
  useEffect(() => {
    props.fetchProducts();
    props.fetchInventory({
      inventoryType: toString(InventoryType, InventoryType.Detergent),
      includeAll: true,
    });
  }, [props.primarySite, props.multiSites]);

  const getDetergentsPage = (page) => {
    props.fetchInventory({ query: { page } });
  };

  const setSelectedRows = (rows) => {
    const selectRow = (rowList, rowData) => {
      if (rowList[rowData._id]) {
        return omit(rowList, [rowData._id]);
      }
      return { ...rowList, [rowData._id]: rowData };
    };

    const selectAll = (rowList, selectedList) => {
      if (Object.keys(rowList).length !== selectedList.length) {
        return keyBy(selectedList, '_id');
      }
      return {};
    };

    let currentSelections = props.selectedDetergents;

    if (rows.length) {
      currentSelections = selectAll(currentSelections, rows);
    } else {
      currentSelections = selectRow(currentSelections, rows);
    }

    props.setDetergentSelection(currentSelections);
  };

  const rowClickFunction = (data) => {
    const keys = Object.keys(props.selectedDetergents);
    if (keys.length > 0) {
      if (keys.find((key) => parseInt(key) === data._id)) {
        setSelectedDetergent({
          selectedDetergent: { ...selectedDetergent, ...data },
        });
        return;
      }
    }
    setSelectedDetergent({
      selectedDetergent: { ...selectedDetergent, ...data },
    });
  };

  const getRowClickInfo = () => ({
    callback: (data) => {
      rowClickFunction(data);
    },
  });

  const initializeDatatable = () => {
    const roundData = (readings) => {
      return readings ? Math.round(readings) : null;
    };

    const currentPod = props.detergentPods.find((pod) => pod.title === props.tabType) || [];
    if (!currentPod?.detergentContainers) {
      return [];
    }

    const data = currentPod.detergentContainers
      .map((container) => ({
        _id: container.id,
        name: container.name,
        capacity: roundData(container.capacity),
        latestReading: container.latestReading && roundData(container.latestReading.value),
        lastReadDate:
          container.latestReading &&
          moment(container.latestReading.timestamp).format('MM/DD/YYYY h:mm:ss a'),
        alarmLevel: roundData(container.alarmLevel),
      }))
      .sort((a, b) => b.position - a.position);

    const headers = [
      { name: props.intl.formatMessage({ id: 'detergentName' }) },
      { name: props.intl.formatMessage({ id: 'capacity' }) },
      { name: props.intl.formatMessage({ id: 'currentLevel' }) },
      { name: props.intl.formatMessage({ id: 'lastRead' }) },
      { name: props.intl.formatMessage({ id: 'alarmLevel' }) },
    ];

    const paginationInfo = {
      ...props.pageInfo,
      goToPage: getDetergentsPage,
    };

    return {
      data,
      headers,
      options: {
        styleRow: {
          backgroundColor: 'white',
        },
      },
      paginationInfo,
      scrolling: true,
      selectedRows: props.selectedDetergents,
      updateSelectedRows: setSelectedRows,
      isLoading: props.isLoading,
      rowClickInfo: getRowClickInfo(),
    };
  };

  return (
    <div>
      <DataTable {...initializeDatatable()} />
    </div>
  );
};

DetergentTableContainer.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventory: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageInfo: PropTypes.shape({}).isRequired,
  showDialog: PropTypes.func.isRequired,
  fetchInventory: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  getDetergent: PropTypes.func.isRequired,
  deleteDetergent: PropTypes.func.isRequired,
  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  detergentPods: selectDetergentPods(state),
  detergents: selectSiteDetergents(state),
  products: selectProducts(state),
  inventory: selectInventory(state),
  role: selectRole(state),
  primarySite: selectSiteExtId(state),
  multiSites: selectMultiSiteSelection(state),
  selectedDetergents: selectSelectedDetergents(state),
  pageInfo: {
    total: selectTotal(state),
    page: selectPage(state),
    pageSize: selectPageSize(state),
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showDialog,
      getDetergent,
      deleteDetergent,
      fetchInventory,
      fetchProducts,
      setDetergentSelection,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(DetergentTableContainer);
