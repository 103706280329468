const taskMessages = {
  en: {
    task: 'Task',
    tasks: 'Tasks',
    addTask: 'Add task',
    editTask: 'Edit task',
    deleteTask: 'Delete task',
    taskName: 'Task name',
    taskDescription: 'Task description',
    taskStatus: 'Task status',
    taskCreated: 'Task created',
    taskUpdated: 'Task updated',
    taskDeleted: 'Task deleted',
    taskStatusTodo: 'Todo',
    taskStatusInProgress: 'In progress',
    taskStatusDone: 'Done',
    taskStatusCanceled: 'Canceled',
    completedTasks: 'Completed Tasks',
    'tasks.schedule.carCount': 'Every {carCount, plural, one {car} other {{carCount} cars}}',
    'tasks.schedule.noCarCount': '*Add valid repeat interval',
    'tasks.schedule.onceAt': 'Once at {carCount} cars',
    'tasks.schedule.onetime': 'One time only',
    'tasks.schedule.hourly': 'Hourly',
    'tasks.schedule.daily':
      '{intervalCount, plural, one {Daily} other {Every {intervalCount} days}}',
    'tasks.schedule.weekly': 'Weekly on {weekdays}',
    'tasks.schedule.weekly.noSchedule': 'Weekly',
    'tasks.schedule.monthly': 'Monthly on the {monthDay}',
    'tasks.schedule.monthly.sameweekday': 'the first {day}',
    'tasks.schedule.yearly': 'Annually on {date}',
    maintenanceTasks: 'Maintenance Tasks',
    projectTasks: 'Project Tasks',
    myProjectList: 'My Project List',
    addNewProject: 'Add New Project',
    projectInformation: 'Project Information',
    projectName: 'Project Name',
    allTasks: 'All Tasks',
    todaysTasks: "Today's Tasks",
    todayChecklist: "Today's Maintenance Tasks",
    masterTasks: 'Master Tasks',
    essentialTasks: 'Essential Tasks',
    addMasterTask: 'Add Master Task',
    addEssentialTask: 'Add Essential Task',
    masterTask: 'Master task',
    openChecklist: 'Open Checklist',
    closingChecklist: 'Closing Checklist',
    cleaningList: 'Cleaning List',
    hourlyInspection: 'Hourly Inspection',
    hourlyInspections: 'Hourly Inspections',
    expressInterior: 'Express Interior',
    redirectToTodaysTasks: 'This task can already be completed in the "Today\'s tasks" list.',
    completeTaskAOT: 'Complete task ahead of time',
    willSkipNextTime: 'This will complete the task and skip the next scheduled time.',
    willUpdateCarCountTask:
      'This will complete the task and update the number of cars the task should be completed at.',
    editMasterTask: 'Edit Master Task',
    editEssentialTask: 'Edit Essential Task',
    archiveTask: 'Archive Task',
    taskArchiveConfirmation: 'Are you sure you want to archive task: {name}?',
    snoozeTask: 'Snooze Task',
    completeTask: 'Complete Task',
    editTaskDetails: 'Edit Task Details',
    completeTaskAnyway: 'Complete Task Anyway',
    taskDependenciesPresent: 'Task Dependencies Present',
    taskDependenciesDescription:
      'The task(s) you are trying to complete include dependencies. Please finalize them prior to completing the selected task(s).',
    viewDependencies: 'View Dependencies',
    taskCompletion: 'Task Completion',
    taskUnlinkConfirm: 'Remove link to {name}?',
    removeLink: 'Remove link',
    taskUnlinkDescription: 'You can go back and relink the task later',
    info: 'Info',
    confirmTaskCompletion: 'Confirm Task Completion(s)',
    confirmTaskCompletionDescription: 'Please confirm the completion of the following task(s):',
    confirmTaskSnooze: 'Snooze Task(s)',
    confirmTaskSnoozeDescription: 'Please confirm the snoozing of the following task(s):',
    taskDetails: 'Task Details',
    taskLocation: 'Task Location',
    snooze: 'Snooze',
    preventativeMaintenance: 'Preventative Maintenance',
    toggleFullTask: 'Toggle Full-Width Task',
    taskOptions: 'Task Options',
    collapseTask: 'Collapse Task',
    newTaskDetails: 'Enter New Task Details',
    taskSubTitle: 'Task Subtitle',
    linkedTasks: 'Linked Tasks',
    linkTasksTogether: 'Link Tasks Together',
    instructions: 'Instructions',
    linkTasksInstructions:
      'Select the link type, then search for the task you are trying to link. You can link any amount of tasks by ‘has to be completed before, blocked by and is related to. ',
    linkTasks: 'Link Tasks',
    selectDependency: 'Search for a dependency',
    selectTask: 'Search for a task to link too ...',
    blockedBy: 'is blocked by',
    doneBefore: 'has to be done before',
    doneAfter: 'has to be done after',
    thisTaskHasToBeDoneBefore: 'This has a task that has to be done before ',
    theseTasksHaveToBeDoneBefore: 'These tasks have to be done before ',
    addNewTask: 'Add New Task',
    estimatedDueDate: 'Estimated Due Date',
  },
};

export default taskMessages;
