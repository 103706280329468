import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SuggestedItemPicker from '../../../core/components/SuggestedItemPicker';
import * as utils from '../../../core/utils';
import { fetchInventory } from '../../inventory/actions';
import { InventoryType } from '../../inventory/models';
import { selectInventory } from '../../inventory/reducer';

class TaskInventoryEdit extends Component {
  componentDidMount() {
    this.props.fetchInventory({
      includeAll: true,
      inventoryType: utils.toString(InventoryType, InventoryType.Part),
    });
  }

  getPartsDiv = () => {
    return this.props.fields.map((item, index) => {
      const part = this.props.fields.get(index);
      return (
        <div className="row" key={index}>
          <div className="col">
            {part.name}
            <button
              className="close multi-form-delete float-right"
              type="button"
              onClick={() => this.props.fields.remove(index)}
            >
              <i className="icon icon-squared-cross" />
            </button>
          </div>
        </div>
      );
    });
  };

  getAvailableInventory = () => {
    // Filter already selected items
    const selectedInventory = this.props.fields.getAll();
    return _.filter(this.props.allInventory, (item) => !_.find(selectedInventory, { id: item.id }));
  };

  selectPart = (partId) => {
    const part = this.props.allInventory.find((i) => i.id === partId);
    if (part) {
      this.props.fields.push(part);
    }
  };

  renderSuggestion = (item) => (
    <div className="suggestion">
      <span>{item.name}</span>
      <small>
        {this.props.intl.formatMessage({ id: 'itemNumber' })}: {item.number}
      </small>
    </div>
  );

  render() {
    const input = {
      onChange: this.selectPart,
    };

    return (
      <div className="">
        <div className="mt-2">{this.getPartsDiv()}</div>
        <div>
          <div className="parts-picker mt-2">
            <SuggestedItemPicker
              containerClassName="d-inline-block w-100"
              input={input}
              items={this.getAvailableInventory()}
              renderSuggestion={this.renderSuggestion}
              clearAfterItemSelected
              placeholder="Add part"
            />
          </div>
        </div>
      </div>
    );
  }
}

TaskInventoryEdit.propTypes = {
  fetchInventory: PropTypes.func.isRequired,
  allInventory: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  fields: PropTypes.shape({
    map: PropTypes.func,
    get: PropTypes.func,
    remove: PropTypes.func,
    push: PropTypes.func,
    getAll: PropTypes.func,
  }),
  intl: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const allInventory = selectInventory(state);
  return { allInventory };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchInventory,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TaskInventoryEdit));
