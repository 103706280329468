import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import ButtonWithLoader from '../../../../core/components/ButtonWithLoader';
import { useDispatch, useSelector } from 'react-redux';
import { showDialog } from '../../../../core/actions';
import {
  addDetergent,
  getDetergentPods,
  postDetergentPodTemplate,
} from '../../../detergents/actions';
import { selectDetergentPods } from '../../../detergents/reducer';
import DetergentPodCard from './DetergentPodCard';
import { Col, Container, Row } from 'reactstrap';
import { selectSiteDetergentTemplateLoaded } from '../../reducer';
import EditDetergents from '../../../detergents/containers/EditDetergents';
import Detergent from '../../../detergents/models';

interface Props {
  siteDetergents: Detergent[];
}

const ManageDetergents = (props: Props): JSX.Element => {
  const { siteDetergents } = props;
  const [detergentPodInEdit, setDetergentPodInEdit] = React.useState<any>(null);
  const dispatch = useDispatch();
  const intl: any = useIntl();
  const detergentPods = useSelector(selectDetergentPods);
  const isDetergentTemplateLoaded: boolean = useSelector(selectSiteDetergentTemplateLoaded);

  useEffect(() => {
    dispatch(getDetergentPods());
  }, []);

  const hideModal = () => dispatch({ type: 'HIDE_DIALOG' });

  const handleAddDetergentPod = () => {
    const dialogProps = {
      onSubmit: addDetergent,
      title: `${intl.formatMessage({ id: 'addNewPod' })}`,
      isNew: true,
      isOpen: true,
      hide: hideModal,
    };
    dispatch(showDialog('UPSERT_DETERGENT_POD', dialogProps));
  };
  const handleTemplateCreation = () => {
    dispatch(postDetergentPodTemplate());
  };

  const editDetergentsInPod = (detergentPod: any) => {
    setDetergentPodInEdit(detergentPod);
  };

  const currentDetergentPod = detergentPods.find(
    (detergentPod: any) => detergentPod.id === detergentPodInEdit?.id
  );

  return (
    <div>
      <div style={{ marginBottom: '10px' }} className="detergent-readings-title-header">
        {intl.formatMessage({ id: 'manageDetergents' })}
      </div>
      {detergentPodInEdit && currentDetergentPod ? (
        <div>
          <label style={{ fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'edit' })} {currentDetergentPod.title}
          </label>
          <EditDetergents
            detergentPod={currentDetergentPod}
            handleBack={() => setDetergentPodInEdit(null)}
            siteDetergents={siteDetergents}
          />
        </div>
      ) : (
        <div style={{ maxWidth: '1400px' }}>
          <Container fluid>
            <Row xs="1" sm="1" md="1" lg="2" xl="3" style={{ marginBottom: '50px' }}>
              {detergentPods.map((detergentPod: any) => {
                return (
                  <Col style={{ flexGrow: '0' }} key={detergentPod.id}>
                    <DetergentPodCard
                      detergentPod={detergentPod}
                      editDetergents={editDetergentsInPod}
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      )}
      {detergentPods.length === 0 && (
        <div style={{ textAlign: 'center', marginBottom: '10px', color: 'rgb(164, 164, 164)' }}>
          {intl.formatMessage({ id: 'noPodsFound' })}
        </div>
      )}
      {!detergentPodInEdit && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <div style={{ paddingRight: '10px' }}>
            <ButtonWithLoader handleClick={handleAddDetergentPod} style="detergent-action-button">
              <span>{intl.formatMessage({ id: 'addDetergentPod' })}</span>
            </ButtonWithLoader>
          </div>
          <div>
            <ButtonWithLoader
              handleClick={handleTemplateCreation}
              style={
                isDetergentTemplateLoaded
                  ? 'detergent-disabled-action-button'
                  : 'detergent-action-button'
              }
              disabled={isDetergentTemplateLoaded}
            >
              <span>{intl.formatMessage({ id: 'loadTommyStandard' })}</span>
            </ButtonWithLoader>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageDetergents;
