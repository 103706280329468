import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useAppContext } from '../../../providers/AppProvider';
import { injectIntl } from 'react-intl';
import DetergentTank from './DetergentTank';
import { map } from 'lodash';
import { selectReadingsFromDate } from '../reducer';
import { useDispatch } from 'react-redux';
import { addDetergentReading } from '../actions';
import ToggleButton from '../../../core/components/ToggleButton';

const DetergentCharts = ({ intl, detergentContainers, detergentPodId }) => {
  const context = useAppContext();
  const [allowRecord, setAllowRecord] = useState(false);
  const fromDate = useSelector(selectReadingsFromDate);
  const [inputValues, setInputValues] = useState({});
  const [isToggledDirection, setToggledDirection] = useState(null);

  const dispatch = useDispatch();
  const toggleScrollDirection = () => {
    setToggledDirection((prevState) => !prevState);
  };

  useEffect(() => {
    if (
      moment(fromDate)
        .tz(context.timeZone)
        .startOf('day')
        .isBefore(moment.tz(context.timeZone).startOf('day'))
    ) {
      setAllowRecord(true);
    } else {
      setAllowRecord(false);
    }
  }, [fromDate, context, context.timeZone]);

  const handleInputChange = (detergentId, value) => {
    const updatedValues = {
      ...inputValues,
      [detergentId]: value,
    };
    setInputValues(updatedValues);
  };

  const handleRecordAll = () => {
    const readings = _.map(inputValues, (value, detergentId) => ({
      containerId: Number(detergentId),
      value,
      timestamp: moment().utc().toISOString(),
    }));
    dispatch(addDetergentReading({ readings, detergentPodId }));
    setInputValues({});
  };
  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleRecordAll();
    }
  };
  return (
    <div>
      <div className="detergent-save-button-container">
        <ToggleButton
          label={intl.formatMessage({ id: 'toggleScrollDirection' })}
          toggleCallback={toggleScrollDirection}
        />
        <button type="button" className="button" onClick={handleRecordAll}>
          {intl.formatMessage({ id: 'saveReadings' })}
        </button>
      </div>
      <div
        className={`scroll-direction ${
          isToggledDirection === null ? '' : isToggledDirection ? 'toggled' : 'untoggled'
        }`}
        onKeyDown={handleEnter}
      >
        <div className="detergent-tank-container">
          {map(
            detergentContainers.sort((a, b) => b.position - a.position),
            (container) => (
              <div key={container.id}>
                <DetergentTank
                  disabledReadings={allowRecord}
                  detergent={container.detergentViewData}
                  detergentPodId={detergentPodId}
                  onInputChange={handleInputChange}
                  inputValue={inputValues[container.id] || ''}
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

DetergentCharts.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  detergentPodId: PropTypes.number.isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object),
  chartData: PropTypes.arrayOf(PropTypes.object),
  asOfDateProps: PropTypes.shape({}).isRequired,
};

export default injectIntl(DetergentCharts);
