import * as api from '../api';

export const getDetergents = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/detergents`, args);
};

export const patchDetergent = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/detergents/${id}`, args);
};

export const postDetergent = (args) => {
  const { siteId } = args.options;
  return api.callPost(`/manager/sites/${siteId}/detergents`, args);
};

export const postDetergents = (args) => {
  const { siteId } = args.options;
  return api.callPost(`/manager/sites/${siteId}/detergents/batch`, args);
};

export const deleteDetergent = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callDelete(`/manager/sites/${siteId}/detergents/${id}`, args);
};

export const getDetergent = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/detergent-containers/${id}`, args);
};

export const postDetergentReading = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/detergents/readings`, args);
};

export const getDetergentChartData = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/detergents/chart-data`, args);
};

export const getDetergentReadings = (_args) => {
  const {
    options: { siteId },
    data: { query, signal },
  } = _args;
  const args = {
    ..._args,
    options: { signal, ..._args.options },
    data: { query: { ...query } },
  };
  return api.callGet(`/manager/sites/${siteId}/detergents/readings`, args);
};

export const archiveDetergent = (args) => {
  return api.callPost(`/manager/sites/${siteId}/detergents/${id}/archive`, {
    options: args.options,
  });
};

export const postBatchArchiveDetergents = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/detergents/batcharchive`, args);
};

export const patchDetergentContainer = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/detergent-containers/${id}`, args);
};

export const getDetergentPods = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/detergent-pods`, args);
};

export const getDetergentPod = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/detergent-pods/${id}`, args);
};

export const postDetergentPod = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/detergent-pods`, args);
};

export const postDetergentsToDetergentPod = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/detergent-pods/${id}/detergents`, args);
};

export const patchDetergentPod = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/detergent-pods/${id}`, args);
};

export const archiveDetergentPod = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/detergent-pods/${id}/archive`, args);
};

export const postDetergentPodTemplate = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/detergent-pods/template`, args);
};
