import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import Big from 'big.js';

interface Props {
  detergent: {
    id: string;
    capacity: string;
    currentCostPerCar: number;
    currentValue: string;
    detergentColor: string;
    position: number;
    goalCostPerCar: number;
    alarmLevel: string;
    label: string;
  };
  detergentPodId: string;
  disabledReadings: boolean;
  onInputChange: (detergentId: string, value: string) => void; // New prop to handle input changes
  inputValue: string; // Prop for the input value
}

const DetergentTank = (props: Props): JSX.Element => {
  const isTankLevelGettingLow =
    Number(props.detergent.currentValue) < Number(props.detergent.alarmLevel);
  const intl: any = useIntl();

  const chartData = {
    labels: [props.detergent.label],
    datasets: [
      {
        label: props.detergent.label,
        data: [`${Big(props.detergent?.currentValue || 0).toFixed(0)}`],
        backgroundColor: '#78D2E0BF',
        color: 'white',
        barPercentage: 1,
        categoryPercentage: 1,
        borderColor: '#d5d5d5',
        font: {
          size: 24,
        },
        borderWidth: 0,
        clip: { right: -7, left: 1, top: 1, bottom: 1 },
      },
    ],
  };

  const options: any = {
    indexAxis: 'x',
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            size: 24,
          },
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        max: props.detergent.capacity,
        ticks: {
          font: {
            size: 24,
          },
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <div style={{ marginRight: '10px', marginLeft: '10px' }}>
      <div>
        <div
          style={{
            display: props.detergent.label.length > 15 ? 'block ruby' : 'flex',
            background: `${props.detergent.detergentColor}`,
          }}
          className="detergent-tank-title"
        >
          {props.detergent.label}
        </div>
        <div className="detergent-capacity-container">
          <div className="detergent-capacity-info">{Big(props.detergent.capacity).toFixed(0)}</div>
        </div>
        <div className="detergent-capacity-container">
          <div className="detergent-bar-chart-container">
            <div className="centered-content">
              {isTankLevelGettingLow && <i className="uil uil-exclamation-circle icon-red" />}
              <div className={`current-value ${isTankLevelGettingLow ? 'value-red' : ''}`}>
                {Big(props.detergent?.currentValue || 0).toFixed(0)}
              </div>
            </div>
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>
      <div className="field custom-width">
        <input
          className={`field ${props.disabledReadings ? 'disabled' : ''}`}
          type="text"
          disabled={props.disabledReadings}
          placeholder={intl.formatMessage({ id: 'gal' })}
          value={props.inputValue}
          onChange={(e) => props.onInputChange(props.detergent.id, e.target.value)} // Update parent on input change
        />
      </div>
    </div>
  );
};

export default DetergentTank;
