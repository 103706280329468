import { get, isNil } from 'lodash';
import moment from 'moment';
import { API_REQUEST } from '../../core/actions';
import * as FileApi from '../../core/api/files';
import * as purchaseOrderCategoryAPI from '../../core/api/purchase-order-categories';
import * as purchaseOrderVendorAPI from '../../core/api/purchase-order-vendors';
import * as purchaseOrderAPI from '../../core/api/purchase-orders';
import * as utilitiesAPI from '../../core/api/utilities';
import * as utilityUnitsAPI from '../../core/api/utility-units';
import { SortDirection } from '../../core/components/DataTableHeaders';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';
import * as utils from '../../core/utils';
import { getUrlParameter } from '../tasks/helpers';

export const FETCH_UTILITY_UNITS = 'FETCH_UTILITY_UNITS';
export const FETCH_UTILITIES = 'FETCH_UTILITIES';
export const GET_UTILITY = 'GET_UTILITY';
export const ADD_UTILITY = 'ADD_UTILITY';
export const SAVE_UTILITY = 'SAVE_UTILITY';
export const DELETE_UTILITY = 'DELETE_UTILITY';
export const FETCH_PURCHASE_ORDERS = 'FETCH_PURCHASE_ORDERS';
export const FETCH_PURCHASE_ORDER = 'FETCH_PURCHASE_ORDER';
export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const FETCH_PURCHASE_ORDER_CATEGORIES = 'FETCH_PURCHASE_ORDER_CATEGORIES';
export const FETCH_PURCHASE_ORDER_VENDORS = 'FETCH_PURCHASE_ORDER_VENDORS';
export const ADD_PURCHASE_ORDER_VENDOR = 'ADD_PURCHASE_ORDER_VENDOR';
export const SET_PURCHASE_ORDER_FROM_DATE = 'SET_PURCHASE_ORDER_FROM_DATE';
export const SET_PURCHASE_ORDER_UNTIL_DATE = 'SET_PURCHASE_ORDER_UNTIL_DATE';
export const RECEIVE_PURCHASE_ORDER = 'RECEIVE_PURCHASE_ORDER';
export const UNRECEIVE_PURCHASE_ORDER = 'UNRECEIVE_PURCHASE_ORDER';
export const SET_FORM_SUBTOTAL = 'SET_FORM_SUBTOTAL';
export const SET_FORM_ADDITIONAL_COST = 'SET_FORM_ADDITIONAL_COST';
export const CLEAR_PURCHASE_ORDER_IN_EDIT = 'CLEAR_PURCHASE_ORDER_IN_EDIT';
export const DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER';
export const EDIT_PURCHASE_ORDER = 'EDIT_PURCHASE_ORDER';
export const ADD_FILE = 'ADD_FILE';
export const DELETE_FILE = 'DELETE_FILE';
// Utilities API
export const fetchUtilityUnits = () => ({
  type: API_REQUEST,
  callAPI: utilityUnitsAPI.fetchUtilityUnits,
  data: {},
  action: FETCH_UTILITY_UNITS,
});

export const fetchUtilities = (query) => ({
  type: API_REQUEST,
  callAPI: utilitiesAPI.fetchUtilities,
  data: {
    query: {
      types: query && query.utilityType ? query.utilityType : getUrlParameter('types'),
      page: (query && query.page) || 0,
    },
  },
  action: FETCH_UTILITIES,
});

export const getUtility = (id) => ({
  type: API_REQUEST,
  callAPI: utilitiesAPI.getUtility,
  data: { id },
  action: GET_UTILITY,
});

export const addUtility = (utility) => ({
  type: API_REQUEST,
  callAPI: utilitiesAPI.postUtility,
  data: utility,
  action: ADD_UTILITY,
  callback: fetchUtilities,
  closeModal: true,
});

export const saveUtility = (utility) => ({
  type: API_REQUEST,
  callAPI: utilitiesAPI.patchUtility,
  data: utility,
  action: SAVE_UTILITY,
  callback: fetchUtilities,
  closeModal: true,
});

export const deleteUtility = (id) => ({
  type: API_REQUEST,
  callAPI: utilitiesAPI.deleteUtility,
  data: { id },
  action: DELETE_UTILITY,
  callback: fetchUtilities,
  closeModal: true,
});

// Purchase Order API

export const fetchPurchaseOrders = (params) => {
  const sortQuery = {
    key: 'id',
    direction: utils.toString(SortDirection, SortDirection.DESC),
  };
  if (get(params, 'sortQuery', false)) {
    const key = get(params.sortQuery, 'key');
    const sortDirection = get(params.sortQuery, 'direction');

    if (key) {
      sortQuery.key = key;
    }
    if (!isNil(sortDirection)) {
      sortQuery.direction = utils.toString(SortDirection, sortDirection);
    }
  }

  return {
    type: API_REQUEST,
    callAPI: purchaseOrderAPI.fetchPurchaseOrders,
    data: {
      query: {
        fromDate:
          params && params.from ? params.from : moment().startOf('day').format('YYYY-MM-DD'),
        untilDate: params && params.until ? params.until : moment().format('YYYY-MM-DD'),
        page: params && params.page ? params.page : 0,
        status: params && params.currentFilters ? params.currentFilters.currentStatus : 'All',
        category: params && params.currentFilters ? params.currentFilters.currentCategory : 'All',
        vendor: params && params.currentFilters ? params.currentFilters.currentVendor : 'All',
        ...sortQuery,
      },
      signal: params && params.signal ? params.signal : false,
    },
    action: FETCH_PURCHASE_ORDERS,
    meta: { permissionKey: viewPermissionRefs.expense },
  };
};

export const fetchPurchaseOrder = (id) => ({
  type: API_REQUEST,
  callAPI: purchaseOrderAPI.fetchPurchaseOrder,
  data: { id },
  action: FETCH_PURCHASE_ORDER,
});

export const addPurchaseOrder = (purchaseOrder, callback) => ({
  type: API_REQUEST,
  callAPI: purchaseOrderAPI.postPurchaseOrder,
  data: purchaseOrder,
  action: ADD_PURCHASE_ORDER,
  callback,
  closeModal: true,
});

export const receivePurchaseOrder = (id, callback) => ({
  type: API_REQUEST,
  callAPI: purchaseOrderAPI.receivePurchaseOrders,
  data: { id },
  action: RECEIVE_PURCHASE_ORDER,
  closeModal: true,
  callback,
});

export const unreceivePurchaseOrder = (id, callback) => ({
  type: API_REQUEST,
  callAPI: purchaseOrderAPI.unreceivePurchaseOrders,
  data: { id },
  action: UNRECEIVE_PURCHASE_ORDER,
  callback,
});

export const deletePurchaseOrder = (id, callback) => ({
  type: API_REQUEST,
  callAPI: purchaseOrderAPI.archivePurchaseOrder,
  data: { id },
  action: DELETE_PURCHASE_ORDER,
  callback,
  closeModal: true,
});

export const editPurchaseOrder = (purchaseOrder, callback) => ({
  type: API_REQUEST,
  callAPI: purchaseOrderAPI.putPurchaseOrder,
  data: purchaseOrder,
  action: EDIT_PURCHASE_ORDER,
  closeModal: true,
  callback,
});

// Purchase Order Category

export const fetchPurchaseOrderCategories = () => ({
  type: API_REQUEST,
  callAPI: purchaseOrderCategoryAPI.fetchPurchaseOrderCategories,
  action: FETCH_PURCHASE_ORDER_CATEGORIES,
});

// Purchase Order Vendors

export const fetchPurchaseOrderVendors = () => ({
  type: API_REQUEST,
  callAPI: purchaseOrderVendorAPI.fetchPurchaseOrderVendors,
  action: FETCH_PURCHASE_ORDER_VENDORS,
  meta: { permissionKey: viewPermissionRefs.expense_vendor },
});

export const purchaseOrderVendor = (values) => {
  return {
    type: API_REQUEST,
    callAPI: purchaseOrderVendorAPI.postPurchaseOrderVendors,
    data: values,
    action: ADD_PURCHASE_ORDER_VENDOR,
    callback: fetchPurchaseOrderVendors,
    closeModal: true,
  };
};

export const addFile = (data) => ({
  type: API_REQUEST,
  callAPI: FileApi.postPurchaseOrderFile,
  data,
  action: ADD_FILE,
  closeModal: true,
  callback: () => fetchPurchaseOrder(data.purchaseOrderId),
});

export const deleteFile = (data) => ({
  type: API_REQUEST,
  callAPI: FileApi.deletePurchaseOrderFile,
  data,
  action: DELETE_FILE,
  closeModal: true,
  callback: () => fetchPurchaseOrder(data.purchaseOrderId),
});

export const setFromDate = (date) => ({
  type: SET_PURCHASE_ORDER_FROM_DATE,
  date,
});
export const setUntilDate = (date) => ({
  type: SET_PURCHASE_ORDER_UNTIL_DATE,
  date,
});
export const setSubTotal = (amount) => ({ type: SET_FORM_SUBTOTAL, amount });
export const setAdditionalCost = (amount) => ({
  type: SET_FORM_ADDITIONAL_COST,
  amount,
});
export const clearPurchaseOrderInEdit = () => ({
  type: CLEAR_PURCHASE_ORDER_IN_EDIT,
});
