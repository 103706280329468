import { PropTypes } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import FilterLink from '../../../core/components/FilterLink';

const InventoryFilters = ({ currentSite, intl, selectedFilter }) => {
  const inventoryTypes = [
    { type: 'Part', message: 'parts' },
    { type: 'Tool', message: 'tools' },
    { type: 'Equipment', message: 'equipment' },
    { type: 'Detergent', message: 'detergent' },
  ];

  const inventoryFilterLinks = inventoryTypes.map((link, index) => {
    return (
      <FilterLink
        key={index}
        pathname={`/${currentSite}/inventory`}
        types={link.type}
        message={intl.formatMessage({ id: link.message })}
        isSelected={selectedFilter === link.type}
      />
    );
  });

  return (
    <div className="generic-filters-container">
      <FilterLink
        pathname={`/${currentSite}/inventory`}
        message={intl.formatMessage({ id: 'all' })}
        isSelected={!selectedFilter}
      />
      {inventoryFilterLinks}
    </div>
  );
};

InventoryFilters.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  currentSite: PropTypes.string.isRequired,
  selectedFilter: PropTypes.string,
};

export default injectIntl(InventoryFilters);
