import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { DetergentContainer } from '../models';
import DetergentReadingEditFields from './DetergentReadingEditFields';

export class DetergentReadingEditForm extends Component {
  static propTypes = {
    detergentContainers: PropTypes.arrayOf(PropTypes.instanceOf(DetergentContainer)),
    fields: PropTypes.arrayOf(PropTypes.shape({ containerId: PropTypes.number.isRequired })),
  };

  getFormProps = () => {
    const { detergentContainers, fields } = this.props;
    const selectedDetergentIds = _.map(fields, 'containerId');

    return {
      detergentContainers,
      createdFields: fields,
      remainingDetergents: detergentContainers.filter(
        (container) => !selectedDetergentIds.includes(container.id)
      ), // eslint-disable-line
    };
  };

  render() {
    return (
      <FieldArray name="readings" component={DetergentReadingEditFields} {...this.getFormProps()} />
    );
  }
}

const selector = formValueSelector('detergentReading');
const mapStateToProps = (state) => {
  return {
    fields: selector(state, 'readings'),
  };
};
const enhance = compose(reduxForm({ form: 'detergentReading' }), connect(mapStateToProps));

export default enhance(DetergentReadingEditForm);
