import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { colorSelect } from '../helpers';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { fieldRequired, Input, Select } from '../../../core/formValidation';
import { Row, Col } from 'reactstrap';
import { detergentSelection, washSelection } from '../helpers';

const DetergentAddForm = ({
  products,
  detergents,
  intl,
  inventory,
  addNew,
  handleDetergentChange,
  handleProductChange,
  selectedProducts,
  selectedDetergent,
}) => {
  return (
    <div>
      <div>
        <Row>
          <Col>
            {!addNew ? (
              <Row>
                <Col>
                  <label className="custom-modal-title">
                    {intl.formatMessage({ id: 'addDetergentToDetergentPod' })}
                  </label>
                  <div className="form-group">
                    <Field
                      name="name"
                      component={() =>
                        detergentSelection({
                          detergents,
                          intl,
                          handleDetergentChange,
                          initialValues: selectedDetergent,
                        })
                      }
                    >
                      {selectedDetergent && selectedDetergent.value && (
                        <option value={selectedDetergent.value}>{selectedDetergent.label}</option>
                      )}
                    </Field>
                  </div>
                </Col>
              </Row>
            ) : (
              <span>
                <label className="custom-modal-title">
                  {intl.formatMessage({ id: 'addDetergents' })}
                </label>
                <div className="form-group">
                  <Field
                    className="form-control"
                    name="name"
                    id="name"
                    component={Input}
                    type="text"
                    aria-describedby="descriptionHelp"
                    placeholder="Enter name for detergent"
                    validate={[fieldRequired]}
                  />
                </div>
              </span>
            )}
          </Col>
        </Row>
        {!addNew && (
          <Row>
            <Col>
              <label htmlFor="capacity">{intl.formatMessage({ id: 'washPlanProducts' })}</label>
              <div className="form-group">
                <Field
                  name="detergentWashes"
                  component={() =>
                    washSelection({
                      products,
                      intl,
                      handleProductChange,
                      selectedProducts,
                    })
                  }
                >
                  {selectedProducts.length &&
                    selectedProducts.map((product) => (
                      <option key={product.value} value={product.value}>
                        {product.label}
                      </option>
                    ))}
                </Field>
              </div>
            </Col>
            <Col>
              <label htmlFor="capacity">{intl.formatMessage({ id: 'capacityGal' })}</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  name="capacity"
                  id="capacity"
                  component={Input}
                  type="number"
                  aria-describedby="descriptionHelp"
                  placeholder="Capacity of the container"
                />
              </div>
            </Col>
            <Col>
              <label htmlFor="alarmLevel">{intl.formatMessage({ id: 'alarmGal' })}</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  name="alarmLevel"
                  id="alarmLevel"
                  component="input"
                  type="number"
                  aria-describedby="descriptionHelp"
                  placeholder="Alarm level"
                  validate={[fieldRequired]}
                />
              </div>
            </Col>
          </Row>
        )}
        {!addNew && (
          <Row>
            <Col>
              <label htmlFor="alarmLevel">{intl.formatMessage({ id: 'position' })}</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  name="position"
                  id="position"
                  component="input"
                  type="number"
                  parse={(value) => Number(value)}
                  aria-describedby="descriptionHelp"
                  placeholder="enter"
                />
              </div>
            </Col>
            <Col>
              <label htmlFor="alarmLevel">{intl.formatMessage({ id: 'autoRorderLevel' })}</label>
              <div className="form-group">
                <Field
                  className="form-control"
                  name="reorderLevel"
                  id="reorderLevel"
                  component={Input}
                  type="number"
                  aria-describedby="descriptionHelp"
                  placeholder="Reorder Level"
                  validate={[fieldRequired]}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          {addNew && (
            <Col>
              <label htmlFor="detergentColor">
                {' '}
                {intl.formatMessage({ id: 'detergentColor' })}
              </label>
              <div className="form-group">
                <Field
                  className="form-control"
                  name="detergentColor"
                  id="detergentColor"
                  component={colorSelect}
                  type="text"
                  aria-describedby="descriptionHelp"
                  placeholder="detergent Color"
                  normalize={(value) => value || ''}
                />
              </div>
            </Col>
          )}
          {!addNew && (
            <Col>
              <div className="form-group">
                <label htmlFor="inventoryItem">
                  {intl.formatMessage({ id: 'relatedInventoryItem' })}
                </label>
                <Field
                  name="inventoryItem"
                  id="inventoryItem"
                  component={Select}
                  className="form-control"
                  parse={Number}
                  onBlur={(e) => {
                    e.preventDefault();
                  }}
                >
                  <option value={-1} disabled>
                    {intl.formatMessage({ id: 'selectInventoryItem' })}
                  </option>
                  {inventory &&
                    inventory.map((inv, i) => (
                      <option key={i} value={inv.id}>
                        {inv.name}
                      </option>
                    ))}
                </Field>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

DetergentAddForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  inventory: PropTypes.arrayOf(PropTypes.shape({})),
  selectedProducts: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDetergent: PropTypes.shape({}),
  addNew: PropTypes.bool,
};

// TODO: add validator
const enhance = compose(injectIntl, reduxForm({ form: 'detergent', enableReinitialize: false }));

export default enhance(DetergentAddForm);
