import { PropTypes } from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ModalDialog = (props) => {
  const {
    children,
    title,
    isOpen,
    close,
    onReady,
    className,
    disabled,
    showFooter,
    onReadyParams,
  } = props;

  const onReadyWithParams = () => {
    onReady(...onReadyParams);
  };
  return (
    <Modal isOpen={isOpen} toggle={close} className={className}>
      <ModalHeader toggle={close} className="custom-detergent-modal-title">
        <div style={{ fontSize: 'large' }}>{title}</div>
      </ModalHeader>
      <ModalBody data-testid="modal">{children}</ModalBody>
      {showFooter && (
        <ModalFooter>
          <Button color="secondary" className="button" onClick={close}>
            {onReady ? 'Cancel' : 'Close'}
          </Button>{' '}
          {onReady && !onReadyParams ? (
            <Button
              color="primary"
              className="button confirm"
              onClick={onReady}
              disabled={disabled}
            >
              Ok
            </Button>
          ) : onReady && onReadyParams ? (
            <Button
              color="primary"
              className="button confirm"
              onClick={onReadyWithParams}
              disabled={disabled}
            >
              Ok
            </Button>
          ) : null}
        </ModalFooter>
      )}
    </Modal>
  );
};

ModalDialog.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  onReady: PropTypes.func,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  showFooter: PropTypes.bool,
  onReadyParams: PropTypes.arrayOf(PropTypes.any),
};

ModalDialog.defaultProps = {
  showFooter: true,
};

export default ModalDialog;
