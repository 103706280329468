import * as api from '../api';

export const fetchInventory = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/inventories`, args);
};

export const getInventoryItem = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/inventories/${id}`, args);
};

export const postInventory = (args) => {
  const {
    options: { siteId },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/inventories`, args);
};

export const fetchDetergentData = (args) => {
  const {
    options: { siteId },
    data: {
      query: { id },
    },
  } = args;
  return api.callGet(`/manager/sites/${siteId}/inventories/${id}/detergent-inventory-data`, args);
};

export const upsertDetergentData = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPost(`/manager/sites/${siteId}/inventories/${id}/detergent-inventory-data`, args);
};

export const patchInventory = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callPatch(`/manager/sites/${siteId}/inventories/${id}`, args);
};

export const deleteInventory = (args) => {
  const {
    options: { siteId },
    data: { id },
  } = args;
  return api.callDelete(`/manager/sites/${siteId}/inventories/${id}`, args);
};
