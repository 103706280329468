import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { SortDirection } from '../../core/components/DataTableHeaders';
import { toString } from '../../core/utils';
import { TaskCompletionStatus, Weekdays } from './types';
import CustomSelectInput from '../../core/components/CustomSelectInput';

export const getWeekdays = (schedule) => {
  if (!schedule) return null;
  const weekdays = [];
  let i;
  for (i = 0; i < schedule.length; i += 1) {
    const day = Number(schedule.charAt(i));
    weekdays.push(moment().day(day).format('dd'));
  }

  weekdays.sort((a, b) => {
    if (Weekdays.indexOf(a) < Weekdays.indexOf(b)) {
      return -1;
    }
    if (Weekdays.indexOf(a) > Weekdays.indexOf(b)) {
      return 1;
    }
    return 0;
  });

  return weekdays.join(', ');
};

export const createIntervalDescription = (props, formatMessage) => {
  const {
    interval,
    intervalCount,
    weeklySchedule,
    monthlySameWeekday,
    start,
    intervalCarCount,
    startingCarCount,
  } = props;
  if (typeof formatMessage !== 'function') throw new Error('formatMessage missing');
  if (interval < 0) throw new Error('invalid value for interval');

  switch (interval) {
    case 0: {
      // based on car count
      if (startingCarCount === null || startingCarCount === undefined) {
        return '';
      }
      if (!intervalCarCount) {
        return formatMessage({ id: 'tasks.schedule.onceAt' }, { carCount: startingCarCount });
      }
      return formatMessage({ id: 'tasks.schedule.carCount' }, { carCount: intervalCarCount });
    }
    case 1: {
      // one-time
      return formatMessage({ id: 'tasks.schedule.onetime' });
    }
    case 2: {
      // daily
      return formatMessage({ id: 'tasks.schedule.daily' }, { intervalCount });
    }
    case 3: {
      // weekly
      const weekdays = getWeekdays(weeklySchedule);
      const hasSchedule = formatMessage(
        { id: 'tasks.schedule.weekly' },
        { intervalCount, weekdays }
      );
      const noSchedule = formatMessage({
        id: 'tasks.schedule.weekly.noSchedule',
      });
      return weekdays ? hasSchedule : noSchedule;
    }
    case 4: {
      // monthly
      const monthDay = monthlySameWeekday
        ? formatMessage(
            { id: 'tasks.schedule.monthly.sameweekday' },
            { day: moment(start).format('dddd') }
          )
        : moment(start).format('Do');
      return formatMessage({ id: 'tasks.schedule.monthly' }, { intervalCount, monthDay });
    }
    case 5: {
      // yearly
      const todayDate = moment(start).format('MMMM Do');
      return formatMessage({ id: 'tasks.schedule.yearly' }, { intervalCount, date: todayDate });
    }
    case 6: {
      // hourly
      return formatMessage({ id: 'tasks.schedule.hourly' });
    }
    default:
      return 'Invalid configuration';
  }
};

export const getUrlParameter = (name) => {
  // eslint-disable-next-line
  const escaped = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${escaped}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getLastCompleted = (latestCompletion, employees) => {
  if (_.isUndefined(latestCompletion)) {
    return '';
  }
  const matchedEmployee = employees.find((e) => e.id === latestCompletion.employeeId) || {};

  return (
    <div>
      <b>{moment(latestCompletion.timestamp).format('MM/DD/YYYY h:mm A')}</b>
      <br />
      {matchedEmployee && (
        <span>
          <span>{matchedEmployee.firstName} </span>
          <span>{matchedEmployee.lastName}</span>
        </span>
      )}
    </div>
  );
};

export const getTodaysTaskCompletionsDefaultQuery = () => {
  return {
    page: 0,
    status: toString(TaskCompletionStatus, TaskCompletionStatus.Pending),
    date: moment().format('YYYY-MM-DD'),
    includeAll: true,
  };
};

export const buildQuery = (defaultQuery, queryParameters, config) => {
  const allFilter = 'All';
  const query = { ...defaultQuery };
  const { typeFilter, prefixFilter, sortQuery, locationFilter } = config;

  if (locationFilter && locationFilter !== allFilter) {
    query.location = locationFilter;
  }
  if (typeFilter && typeFilter !== allFilter) {
    query.type = typeFilter;
  }
  if (prefixFilter && prefixFilter !== allFilter) {
    query.prefix = prefixFilter;
  }
  if (sortQuery) {
    if (sortQuery.key) {
      query.key = sortQuery.key;
    }
    if (!_.isNil(sortQuery.direction)) {
      query.direction = toString(SortDirection, sortQuery.direction);
    }
  }

  return { ...query, ...queryParameters };
};

const mutateInputValue = (option) => {
  return {
    value: option.id,
    label: option.name,
  };
};

export const taskSelection = (props) => {
  // Extract linked task IDs from props.linkedTasks
  const linkedTaskIds = props.linkedTasks?.map((linkedTask) => linkedTask.linkedTask.value) || [];

  // Get the id from props.initialValues
  const initialValueId = props.initialValues?.id;

  // Filter out options that are already linked or equal to props.initialValues.id
  const filteredOptions = props.options.filter((option) => {
    return !linkedTaskIds.includes(option.id) && option.id !== initialValueId;
  });

  return (
    <CustomSelectInput
      options={filteredOptions.map((option) => ({ value: option.id, label: option.name }))}
      getOptionValue={(option) => option.label}
      placeholder={props.intl.formatMessage({ id: props.placeholder })}
      onChange={(value) => props.input.onChange({ value: value.value, name: value.label })}
      onBlur={(value) => props.input.onBlur([value])}
      isSearchable
      value={props.input.value ? mutateInputValue(props.input.value) : 'Select an option'}
      defaultValue={props.input.value ? mutateInputValue(props.input.value) : 'Select an option'}
      meta={props?.meta}
    />
  );
};
