import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { selectEmployees } from '../../employees/reducer';
import ArchiveDetergentsForm from '../components/ArchiveDetergentsForm';
import { clearDetergentSelections } from '../actions';

class ArchiveDetergentsDialog extends Component {
  static propTypes = {
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    archivedDetergents: PropTypes.shape({}),
    onReady: PropTypes.func.isRequired,
    clearDetergentSelections: PropTypes.func.isRequired,
  };

  onReady = () => {
    this.props.triggerSubmit('detergentsForm');
  };

  onSubmit = () => {
    this.props.clearDetergentSelections();
    this.props.onReady();
  };

  render() {
    const { closeDialog, isOpen, archivedDetergents, title, text } = this.props;
    return (
      <ModalDialog title={title} onReady={this.onReady} isOpen={isOpen} close={closeDialog}>
        <ArchiveDetergentsForm
          onSubmit={this.onSubmit}
          title={title}
          text={text}
          enableReinitialize
          detergent={archivedDetergents}
        />
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => ({ employees: selectEmployees(state) });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeDialog: hideDialog,
      triggerSubmit: submit,
      clearDetergentSelections,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveDetergentsDialog);
