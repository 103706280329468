import * as utils from '../../core/utils';

export const InventoryType = {
  Part: 0,
  Tool: 1,
  Equipment: 2,
  Detergent: 3,
};

export const ContainerSizes = [2.6, 5.2, 15, 30, 55];

export class Inventory {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.number = props.number;
    this.type = utils.toEnum(InventoryType, props.type);
    this.siteId = props.siteId;
    this.description = props.description;
    this.installedAt = props.installedAt;
    this.preferredStockLevel = props.preferredStockLevel;
    this.createdAt = props.createdAt;
    this.vendorUrl = props.vendorUrl;
    this.inventoryData = props.inventoryData;
    this.unitOfMeasure = props.unitOfMeasure;
  }
}
