import { invert as _invert } from 'lodash';
import { toEnum, toString } from '../../core/utils';
import { UtilityType } from './models';

/* eslint-disable import/prefer-default-export */
export const utilityHelper = {
  ensureCurrentUnitExists: (units, typeKey, unitKey) => {
    const checkedUnits = { ...units };
    const availableUnits = { ...checkedUnits[typeKey] };
    if (!availableUnits[unitKey]) {
      availableUnits[unitKey] = Object.keys(availableUnits).length;
      checkedUnits[typeKey] = availableUnits;
    }
    return checkedUnits;
  },
  utilityTypeEnumKeyFromVal: (typeVal) => {
    return toString(UtilityType, typeVal);
  },

  utilityTypeEnumValFromKey: (typeKey) => {
    return toEnum(UtilityType, typeKey);
  },

  utilityUnitEnumKeyFromVal: (unitVal, typeKey, utilityUnits) => {
    if (Number.isNaN(Number(unitVal))) {
      return unitVal;
    }
    const currentUnit = utilityUnits[typeKey];
    return _invert(currentUnit)[unitVal];
  },

  utilityUnitEnumValFromKey: (unitKey, typeKey, utilityUnits) => {
    if (!Number.isNaN(Number(unitKey))) {
      return unitKey;
    }
    return utilityUnits[typeKey][unitKey];
  },
};
