import pbi from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class Embed extends PureComponent {
  constructor(props) {
    super(props);
    this.component = null;
  }

  // This is left here in case we want to inform
  // the user of errors in configs
  validateConfig = () => {
    const { config } = this.props;

    return pbi.models.validateReportLoad(config);
  };

  handleLoaded = () => {
    this.props.performOnEmbed(this.component);
  };

  handleEmbeddedComponent = (embeddedReport) => {
    this.component = embeddedReport;
  };

  getEventHandlers = () => {
    return new Map([['loaded', this.handleLoaded]]);
  };

  getConfig = () => {
    const bootstrapConfig = {
      type: 'report',
      embedUrl: undefined,
      tokenType: pbi.models.TokenType.Embed,
      accessToken: undefined,
      settings: undefined,
    };

    if (!this.props.config) {
      return bootstrapConfig;
    }

    return {
      ...bootstrapConfig,
      ...this.props.config,
    };
  };

  render() {
    return (
      <PowerBIEmbed
        cssClassName="powerbi-iframe"
        embedConfig={this.getConfig()}
        eventHandlers={this.getEventHandlers()}
        getEmbeddedComponent={this.handleEmbeddedComponent}
      />
    );
  }
}

Embed.propTypes = {
  config: PropTypes.shape({}).isRequired,
  performOnEmbed: PropTypes.func.isRequired,
};

export default Embed;
