import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { omit, keyBy, filter } from 'lodash';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { useSelector } from 'react-redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import { toString } from '../../../core/utils';
import { selectRole } from '../../../store/authReducer';
import { fetchInventory } from '../../inventory/actions';
import { InventoryType } from '../../inventory/models';
import { selectInventory } from '../../inventory/reducer';
import { fetchProducts } from '../../products/actions';
import { selectProducts } from '../../products/reducer';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import {
  archiveDetergent,
  batchArchiveDetergents,
  getDetergent,
  setDetergentSelection,
  getDetergents,
  handleDetergentsToDetergentPod,
} from '../actions';
import isObjectLike from 'lodash/isObjectLike';

import UserGroupPermissions, {
  deletePermissionRefs,
} from '../../../core/components/UserGroupPermissions';
import map from 'lodash/map';
import { selectPage, selectPageSize, selectSelectedDetergents, selectTotal } from '../reducer';
import { Container } from 'reactstrap';
import ButtonWithLoader from '../../../core/components/ButtonWithLoader';
import { hasRole } from '../../../core/components/Permission';
import AddDetergentDialog from './AddDetergentDialog';
import EditDetergentDialog from './EditDetergentDialog';

export const DetergentTableContainer = ({ ...props }) => {
  const [selectedDetergent, setSelectedDetergent] = useState({});
  const intl = useIntl();
  const currentRole = useSelector(selectRole);

  useEffect(() => {
    props.fetchProducts();
    props.fetchInventory({
      inventoryType: toString(InventoryType, InventoryType.Detergent),
      includeAll: true,
    });
    props.getDetergents();
  }, [props.primarySite, props.multiSites]);

  useEffect(() => {
    initializeDatatable();
  }, [props.detergentContainers]);

  useEffect(() => {
    return () => {
      props.setDetergentSelection({});
    };
  }, []);

  const editDetergent = (event, { _id }) => {
    props.getDetergent(_id);
    props.showDialog('EDIT_DETERGENT', {
      specificComponent: EditDetergentDialog,
      products: props.products,
      inventory: props.inventory,
      detergents: props.siteDetergents,
      detergentContainerId: _id,
      title: intl.formatMessage({ id: 'editDetergent' }),
    });
  };

  const addNewDetergent = (addNew) => {
    props.showDialog('ADD_DETERGENT', {
      specificComponent: AddDetergentDialog,
      products: props.products,
      inventory: props.inventory,
      detergents: props.siteDetergents,
      detergentPodId: props.detergentPodId,
      addNew,
      title: intl.formatMessage({ id: 'addDetergent' }),
    });
  };

  const handleArchive = (event, archivedDetergents) => {
    const detergentContainerIds =
      isObjectLike(archivedDetergents) && !archivedDetergents._id
        ? map(archivedDetergents, '_id')
        : archivedDetergents._id;

    const formValues = {
      detergentPodId: props.detergentPodId,
      detergentContainerIds,
    };

    const dialogProps = {
      onReady: () => props.handleDetergentsToDetergentPod(formValues, true),
      title: intl.formatMessage({ id: 'archive' }),
      text: intl.formatMessage({ id: 'removeDetergentFromDetergentPod' }),
    };
    event.stopPropagation();
    props.showDialog('ARCHIVE_DETERGENT_DIALOG', dialogProps);
  };

  const getDetergentsPage = (page) => {
    props.fetchInventory({ query: { page } });
  };

  const setSelectedRows = (rows) => {
    const selectRow = (rowList, rowData) => {
      if (rowList[rowData._id]) {
        return omit(rowList, [rowData._id]);
      }
      return { ...rowList, [rowData._id]: rowData };
    };

    const selectAll = (rowList, selectedList) => {
      if (Object.keys(rowList).length !== selectedList.length) {
        return keyBy(selectedList, '_id');
      }
      return {};
    };

    let currentSelections = props.selectedDetergents;

    if (rows.length) {
      currentSelections = selectAll(currentSelections, rows);
    } else {
      currentSelections = selectRow(currentSelections, rows);
    }

    props.setDetergentSelection(currentSelections);
  };

  const rowClickFunction = (data) => {
    const keys = Object.keys(props.selectedDetergents);
    if (keys.length > 0) {
      if (keys.find((key) => parseInt(key) === data._id)) {
        setSelectedDetergent({
          selectedDetergent: { ...selectedDetergent, ...data },
        });
        return;
      }
    }
    setSelectedDetergent({
      selectedDetergent: { ...selectedDetergent, ...data },
    });
    editDetergent(null, data);
  };

  const getRowClickInfo = () => ({
    callback: (data) => {
      rowClickFunction(data);
    },
  });

  const initializeDatatable = () => {
    const roundData = (readings) => {
      return readings ? Math.round(readings) : null;
    };

    const baseStyle = {
      fontFamily: 'Avenir Next',
      fontSize: '25px',
      fontWeight: '600',
      lineHeight: '17px',
      letterSpacing: '0px',
      textAlign: 'center',
      border: '1px solid #000000',
      padding: '15px',
    };

    const data = props?.detergentContainers
      .sort((a, b) => a.position - b.position) // Sort detergents by position
      .map((detergent) => ({
        _id: detergent.id,
        detergents: {
          name: detergent.name,
          style: {
            backgroundColor: detergent?.detergentColor ?? '#D9D9D9',
            color: 'white', // Example font color (modify as needed)
            textAlign: 'center',
            fontFamily: 'Avenir Next',
            fontSize: '20px',
            fontWeight: 700,
            // Add more CSS properties as needed to customize the cell's style
          },
        },
        capacity: {
          name: roundData(detergent.capacity),
          style: baseStyle,
        },
        alarmLevel: {
          name: roundData(detergent.alarmLevel),
          style: baseStyle,
        },
        reorderLevel: {
          name: roundData(detergent.reorderLevel),
          style: baseStyle,
        },
        washProducts: {
          name: detergent.products.map((product) => product.name).join(', '), // Join product names
          style: baseStyle,
        },
        relatedEquipment: {
          name: detergent.inventory ? detergent.inventory.name : '',
          style: baseStyle,
        },
      }));

    const headers = [
      {
        name: intl.formatMessage({ id: 'detergents' }),
      },
      { name: intl.formatMessage({ id: 'capacity' }) },
      { name: intl.formatMessage({ id: 'alarmLevel' }) },
      { name: intl.formatMessage({ id: 'reorderLevel' }) },
      { name: intl.formatMessage({ id: 'washProducts' }) },
      { name: intl.formatMessage({ id: 'relatedEquipment' }) },
    ];

    const paginationInfo = {
      ...props.pageInfo,
      goToPage: getDetergentsPage,
    };

    return {
      data,
      headers,
      options: {
        isSelect: true,
        styleRow: {
          backgroundColor: 'white',
        },
      },
      datatable: {
        styles: 'manage-detergent-table',
      },
      addedClass: 'custom-padding-class',
      paginationInfo,
      scrolling: true,
      selectedRows: props.selectedDetergents,
      updateSelectedRows: setSelectedRows,
      multiSelectEnabled: true,
      isLoading: props.isLoading,
      rowClickInfo: getRowClickInfo(),
    };
  };

  return (
    <Container>
      <div className="detergent-button-container">
        <div>
          <div>
            <UserGroupPermissions itemRef={deletePermissionRefs.detergent}>
              <ButtonWithLoader
                handleClick={() => addNewDetergent(true)}
                style="detergent-action-button mr-2"
              >
                <div>{intl.formatMessage({ id: 'addNew' })}</div>
              </ButtonWithLoader>
            </UserGroupPermissions>
            <ButtonWithLoader
              handleClick={() => addNewDetergent(false)}
              style="detergent-action-button mr-2"
            >
              <div>{intl.formatMessage({ id: 'addDetergentContainer' })}</div>
            </ButtonWithLoader>
            <ButtonWithLoader handleClick={props.handleBack} style="detergent-action-button mr-2">
              <div>{intl.formatMessage({ id: 'back' })}</div>
            </ButtonWithLoader>
            {Object.keys(props.selectedDetergents).length > 0 && (
              <button
                className="detergent-action-button"
                disabled={!Object.keys(props.selectedDetergents).length}
                onClick={(e) => {
                  handleArchive(e, props.selectedDetergents);
                }}
              >
                {intl.formatMessage({ id: 'archive' })}(
                {Object.keys(props.selectedDetergents).length > 0
                  ? Object.keys(props.selectedDetergents).length
                  : ''}
                )
              </button>
            )}
          </div>
        </div>
      </div>
      <div>
        <DataTable {...initializeDatatable()} />
      </div>
    </Container>
  );
};

DetergentTableContainer.propTypes = {
  detergentPodId: PropTypes.number.isRequired,
  detergentContainers: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteDetergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventory: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageInfo: PropTypes.shape({}).isRequired,
  showDialog: PropTypes.func.isRequired,
  fetchInventory: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  getDetergent: PropTypes.func.isRequired,
  archiveDetergent: PropTypes.func.isRequired,
  batchArchiveDetergents: PropTypes.func.isRequired,
  selectedDetergents: PropTypes.shape({}).isRequired,

  role: PropTypes.string,
};

const mapStateToProps = (state) => ({
  products: selectProducts(state),
  inventory: selectInventory(state),
  role: selectRole(state),
  primarySite: selectSiteExtId(state),
  multiSites: selectMultiSiteSelection(state),
  selectedDetergents: selectSelectedDetergents(state),
  pageInfo: {
    total: selectTotal(state),
    page: selectPage(state),
    pageSize: selectPageSize(state),
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showDialog,
      getDetergent,
      archiveDetergent,
      getDetergents,
      batchArchiveDetergents,
      fetchInventory,
      fetchProducts,
      setDetergentSelection,
      handleDetergentsToDetergentPod,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl);

export default enhance(DetergentTableContainer);
