import Product from '../products/models';

export class DetergentPod {
  constructor(props = {}) {
    this.id = props.id;
    this.title = props.title;
    this.description = props.description;
    this.siteId = props.siteId;

    if (props.detergentContainers) {
      this.detergentContainers = props.detergentContainers.map((c) => new DetergentContainer(c));
    }
  }

  static mapChartData(detergents) {
    return !detergents
      ? []
      : detergents.map((pod) => ({
          id: pod.id,
          label: pod.name,
          alarmLevel: pod.alarmLevel,
          detergentColor: pod.detergentColor,
          position: pod.position,
          currentValue: pod.latestReading ? pod.latestReading.value : null,
          capacity: pod.capacity,
          currentCostPerCar: 0, // TODO
          goalCostPerCar: 0.05, // TODO
        }));
  }
}

export class DetergentContainer {
  constructor(props = {}) {
    this.id = props.id;
    this.capacity = props.capacity;
    this.alarmLevel = props.alarmLevel;
    this.reorderLevel = props.reorderLevel;
    this.siteId = props.siteId;
    this.detergentPodId = props.detergentPodId;
    this.detergentId = props.detergentId;
    this.detergentColor = props.color || props.detergent.detergentColor;
    this.position = props.position || props.detergent.position;
    this.name = props.name || props.detergent.name;

    if (props.inventory) {
      this.inventory = props.inventory[0];
    }
    if (props.latestReading) {
      this.latestReading = new DetergentReading(props.latestReading);
    }
    if (props.products) {
      this.products = props.products.map((p) => new Product(p));
    }

    if (props.detergent) {
      this.detergent = new Detergent(props.detergent);
      this.detergentViewData = {
        id: props.id,
        label: props.detergent.name,
        alarmLevel: props.alarmLevel,
        detergentColor: props.color,
        position: props.position,
        currentValue: props.latestReading ? props.latestReading.value : null,
        capacity: props.capacity,
        currentCostPerCar: 0, // TODO
        goalCostPerCar: 0.05, // TODO
      };
    }
  }
}

class Detergent {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.detergentColor = props.detergentColor;
    this.modifier = props.modifier;
    this.modifiedAt = props.modifiedAt;
    this.siteId = props.siteId;
    this.position = props.position;
    this.detergentContainerId = props.detergentContainerId;
  }

  static mapChartData(detergents) {
    return !detergents
      ? []
      : detergents.map((d) => ({
          label: d.name,
          detergentPodId: d.detergentPodId,
          alarmLevel: d.alarmLevel,
          currentValue: d.latestReading ? d.latestReading.value : null,
          capacity: d.capacity,
          currentCostPerCar: 0, // TODO
          goalCostPerCar: 0.05, // TODO
        }));
  }
}

export class DetergentReading {
  constructor(props = {}) {
    this.id = props.id;
    this.value = props.value;
    this.timestamp = props.timestamp;
    this.createdAt = props.createdAt;
    this.creator = props.creator;
    this.modifier = props.modifier;
    this.modifiedAt = props.modifiedAt;
    this.detergentId = props.detergentId;
  }
}

export default Detergent;
