import { API_REQUEST } from '../../core/actions';
import * as API from '../../core/api/inventory';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';
import { getUrlParameter } from '../tasks/helpers';

export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const GET_INVENTORY_ITEM = 'GET_INVENTORY_ITEM';
export const ADD_INVENTORY_ITEM = 'ADD_INVENTORY_ITEM';
export const FETCH_DETERGENT_DATA = 'FETCH_DETERGENT_DATA';
export const UPSERT_DETERGENT_DATA = 'UPSERT_DETERGENT_DATA';
export const SAVE_INVENTORY_ITEM = 'SAVE_INVENTORY_ITEM';
export const DELETE_INVENTORY_ITEM = 'DELETE_INVENTORY_ITEM';
export const DELETE_INVENTORYINEDIT = 'DELETE_INVENTORYINEDIT';
export const CLEAR_INVENTORY_DATA = 'CLEAR_INVENTORY_DATA';

export const fetchInventory = (query) => {
  return {
    type: API_REQUEST,
    callAPI: API.fetchInventory,
    data: {
      query: {
        types: query && query.inventoryType ? query.inventoryType : getUrlParameter('types'),
        page: (query && query.page) || 0,
        includeAll: query ? query.includeAll : false,
      },
    },
    meta: { permissionKey: viewPermissionRefs.inventory, useManagerSiteId: true },
    action: FETCH_INVENTORY,
  };
};

export const getInventoryItem = (id) => ({
  type: API_REQUEST,
  callAPI: API.getInventoryItem,
  data: { id },
  action: GET_INVENTORY_ITEM,
});

export const addInventoryItem = (data) => {
  return {
    type: API_REQUEST,
    callAPI: API.postInventory,
    data: data.item,
    action: ADD_INVENTORY_ITEM,
    callback: fetchInventory,
    closeModal: true,
  };
};

export const fetchDetergentData = (query, closeModal = true) => {
  return {
    type: API_REQUEST,
    callAPI: API.fetchDetergentData,
    data: { query },
    action: FETCH_DETERGENT_DATA,
    closeModal,
  };
};

export const upsertDetergentData = (data) => {
  const query = {
    id: data.id,
    direction: 'desc',
    page: 0,
  };
  return {
    type: API_REQUEST,
    callAPI: API.upsertDetergentData,
    data,
    action: UPSERT_DETERGENT_DATA,
    callback: data.callback || (() => fetchDetergentData(query)),
    closeModal: true,
  };
};

export const saveInventoryItem = (data) => {
  return {
    type: API_REQUEST,
    callAPI: API.patchInventory,
    data: data.item,
    action: SAVE_INVENTORY_ITEM,
    callback: data.callback || fetchInventory,
    closeModal: true,
  };
};

export const deleteInventoryItem = (item) => {
  return {
    type: API_REQUEST,
    callAPI: API.deleteInventory,
    data: { id: item.id },
    action: DELETE_INVENTORY_ITEM,
    navigate: item.navigate,
    callback: item.callback || fetchInventory,
    closeModal: true,
  };
};

export const deleteInventoryInEdit = () => ({ type: DELETE_INVENTORYINEDIT });
