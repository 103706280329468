import { Inventory } from './models';

const initialState = {
  inventory: [],
  inventoryInEdit: {},
  inventoryData: [],
};

const fetchInventorySuccess = (state, response) => {
  const inventory = response.results.map((i) => new Inventory(i));
  return {
    ...state,
    inventory,
    page: response.page,
    total: response.total,
    pageSize: response.pageSize,
  };
};

const fetchDetergentDataSuccess = (state, response) => {
  return {
    ...state,
    inventoryData: response.results,
  };
};

const getInventoryItemSuccess = (state, inventory) => {
  return {
    ...state,
    inventoryInEdit: new Inventory(inventory),
  };
};

const deleteInventoryInEdit = (state) => {
  return {
    ...state,
    inventoryInEdit: null,
  };
};

const clearInventoryData = (state) => {
  return {
    ...state,
    inventoryData: [],
  };
};

export const inventory = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INVENTORY_SUCCESS':
      return fetchInventorySuccess(state, action.response);
    case 'FETCH_DETERGENT_DATA_SUCCESS':
      return fetchDetergentDataSuccess(state, action.response);
    case 'GET_INVENTORY_ITEM_SUCCESS':
      return getInventoryItemSuccess(state, action.response);
    case 'DELETE_INVENTORYINEDIT':
      return deleteInventoryInEdit(state);
    case 'CLEAR_INVENTORY_DATA':
      return clearInventoryData(state);
    default:
      return state;
  }
};

export const selectInventory = (state) => {
  return state.inventory.inventory;
};

export const selectInventoryInEdit = (state) => {
  return state.inventory.inventoryInEdit;
};

export const selectTotal = (state) => {
  return state.inventory.total;
};

export const selectPage = (state) => {
  return state.inventory.page;
};

export const selectPageSize = (state) => {
  return state.inventory.pageSize;
};

export const selectInventoryData = (state) => {
  return state.inventory.inventoryData;
};
